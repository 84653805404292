import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import CardBodyErrorBoundary from "./CardBodyErrorBoundary";
import LoadingSpinner from "../../../svg/LoadingSpinner";
import Loading from "../Loading";

function CardBody(props) {
  return (
    <CardBodyErrorBoundary>
      <div className={classNames("card-body", props.className)}>
        {props.loading ? (
          <Loading />
        ) : (
          props.children
        )}
      </div>
    </CardBodyErrorBoundary>
  );
}

CardBody.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string
};

export default CardBody;
