import moment from "moment";

// we have to convert to utc first
// todo: a missing .utc() might be causing errors elsewhere!!
export const toDate = (date, formatString) => {
  if (formatString) {
    return moment.utc(date).format(formatString);
  } else {
    return moment.utc(date).toDate();
  }
};

export const dataObjectToDate = (data, formatString) => {
  if (formatString) {
    return {
      ...data,
      x: data.x.map(x => toDate(x / 1000 / 1000, formatString))
    };
  } else {
    return {
      ...data,
      x: data.x.map(x => toDate(x / 1000 / 1000))
    };
  }
};

export const convertToCategorySeries = (xData, yData, xFormatter) =>
  xData.map((x, ix) => ({
    value: [xFormatter ? xFormatter(x) : x, yData[ix]]
  }));
