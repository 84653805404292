import * as types from "./types";

const initialState = {
  instruments: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {

    case types.GET_ACTIVE_INSTRUMENTS_FULFILLED:
      return { ...state, instruments: action.payload };

    default:
      return state;
  }
}
