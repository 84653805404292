import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Autosuggest from "react-autosuggest";
import "./MarketSearchMini.css";

function MarketSearchMini(props) {
  const { instruments } = props;
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : instruments.filter(
          instrument =>
            instrument.name.toLowerCase().slice(0, inputLength) === inputValue
        ).concat(instruments.filter(
            instrument =>
              instrument.ticker.toLowerCase().slice(0, inputLength) === inputValue
          ));
  };

  const getSuggestionValue = suggestion => suggestion.name;

  const renderSuggestion = suggestion => <span>{suggestion.name} ({suggestion.ticker})</span>;

  const onChange = (event, { newValue }) => {
    setValue(newValue);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setValue("");
    setSuggestions([]);
  };

  const onSuggestionSelected = (e, { suggestion }) => {
    // console.log("selected: ", suggestion);
    const location = props.location.pathname.split("/").slice(-1)[0];
    props.history.push(`/market/${suggestion.ticker.toLowerCase()}/${location}`);
    setValue("");
  };

  const inputProps = {
    placeholder: "Search Markets",
    // className: "form-control",
    // style: {border: 0},
    value,
    onChange: onChange
  };

  return (
    <div>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
      />
    </div>
  );
}

MarketSearchMini.propTypes = {
  instruments: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  instruments: state.market.instruments
});

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(MarketSearchMini)
);
