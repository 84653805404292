import React, { Component } from 'react'

export default class CardBodyErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
      }
    
      static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        console.log("error: ", error)
        return { hasError: true };
      }
    
      // componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
        // this.setState({hasError: true})
      // }
    
      render() {
        if (this.state.hasError) {
          // You can render any custom fallback UI
          return <p>There was an error loading the data.</p>;
        }
    
        return this.props.children; 
      }
}
