import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
  getHistoricalData,
  getCharts
} from "../../../../../../../services/market";
import { dataObjectToDate, toDate } from "../../../../../../../utils/converter";
import Card from "../../../../../../layout/card/Card";
import CardBody from "../../../../../../layout/card/CardBody";
import SeasonalityChart from "./SeasonalityChart";

function SeasonalityCard({ ticker }) {
  const [type, setType] = useState("year");
  const [loading, setLoading] = useState(true);
  const [currentData, setCurrentData] = useState(null);
  const [charts, setCharts] = useState(null);
  const [chartDescription, setChartDescription] = useState("")
  const [activeChart, setActiveChart] = useState(null);

  const getYearData = charts => {
    const dt = moment()
      .startOf("year")
      .toDate();
    const params = {
      ticker: ticker,
      start_datetime: dt.getTime()
    };
    getHistoricalData(params).then(data => {
      setCurrentData(dataObjectToDate(data.data, "MM-DD"));
      let chart = charts.find(x => x.chart_type === "year_avg")
      chart = {...chart, x: chart.x.map(x => toDate(x, "MM-DD"))};
      setChartDescription({title: "Average Year", description: "Average year and current year."})
      setActiveChart(chart);
      setLoading(false);
    });
  };

  const getMonthData = charts => {
    const dt = moment()
      .startOf("month")
      .toDate();
    const params = {
      ticker: ticker,
      start_datetime: dt.getTime()
    };
    getHistoricalData(params).then(data => {
      setCurrentData(dataObjectToDate(data.data, "D"));
      let chart = charts.find(x => x.chart_type === "month_avg")
      chart = {...chart, x: chart.x.map(x => +x)};
      setChartDescription({title: "Average Month", description: "Average month and current month."})
      setActiveChart(chart);
      setLoading(false);
    });
  };

  const getWeekData = charts => {
    const dt = moment()
      .startOf("isoweek")
      .toDate();
    const params = {
      ticker: ticker,
      start_datetime: dt.getTime(),
      resolution: "15M"
    };
    getHistoricalData(params).then(data => {
      setCurrentData(dataObjectToDate(data.data, "ddd HH:mm"));
      let chart = charts.find(x => x.chart_type === "week_avg")
      chart = {...chart, x: chart.x.map(x => toDate(x, "ddd HH:mm"))};
      setChartDescription({title: "Average Week", description: "Average week and current week."})
      setActiveChart(chart);
      setLoading(false);
    });
  };

  // update chart data when ticker changes
  useEffect(() => {
    getCharts(ticker, ["year_avg", "month_avg", "week_avg"]).then(data =>
      setCharts(data)
    );
  }, [ticker]);
  
  // update current data when type or charts change
  useEffect(() => {
    setLoading(true);
    if (charts) {
      if (type === "year") {
        getYearData(charts);
      } else if (type === "month") {
        getMonthData(charts);
      } else if (type === "week") {
        getWeekData(charts);
      }
    }
  }, [type, charts]);

  const btnClass = "btn btn-xs btn-white btn-uppercase"

  return (
    <Card>
      <div
        className="btn-group mg-t-20 mg-sm-t-0"
        style={{
          position: "absolute",
          right: 20,
          top: 20,
          zIndex: 1005
        }}
      >
        <button
          className={type === "week" ? btnClass + " active" : btnClass}
          onClick={() => setType("week")}
        >
          Week
        </button>
        <button
          className={type === "month" ? btnClass + " active" : btnClass}
          onClick={() => setType("month")}
        >
          Month
        </button>
        <button
          className={type === "year" ? btnClass + " active" : btnClass}
          onClick={() => setType("year")}
        >
          Year
        </button>
      </div>
      <CardBody>
        {!loading && (
          <SeasonalityChart currentData={currentData} avgData={activeChart} chartDescription={chartDescription} />
        )}
      </CardBody>
    </Card>
  );
}

SeasonalityCard.propTypes = {
  ticker: PropTypes.string.isRequired
};

export default SeasonalityCard;
