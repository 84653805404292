import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { checkLogin } from "../../redux/ducks/account/actions";
import Loading from "../layout/Loading";

function PublicRouteOnly({
  children,
  loggedIn,
  loadingUser,
  checkLogin,
  ...rest
}) {

  useEffect(() => {
    if (!loadingUser && !loggedIn) {
      checkLogin();
    }
  }, [loggedIn]);

  if (loadingUser) {
    return (
      <div style={{ height: "50%" }}>
        <Loading />
      </div>
    );
  }

  if (!loggedIn) {
    return <Route {...rest} />;
  } else {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  loggedIn: state.account.loggedIn,
  loadingUser: state.account.loadingUser,
});

export default connect(mapStateToProps, { checkLogin })(PublicRouteOnly);
