export const chartWatermark = {
  type: "text",
  right: "center",
  bottom: "center",
  // z: 999,
  // zlevel:999,
  style: {
    fill: "rgb(30, 40, 52, 0.5)",
    text: "MARKETSEASONALS.COM".split("").join(String.fromCharCode(8201)),
    font: "bold 10px Open Sans",
    zIndex: 900
  }
};

export const chartWatermarkBottomLeft = {
  type: "text",
  left: "12%",
  bottom: "25%",
  // z: 999,
  // zlevel:999,
  style: {
    fill: "rgb(30, 40, 52, 0.5)",
    text: "MARKETSEASONALS.COM".split("").join(String.fromCharCode(8201)),
    font: "bold 10px Open Sans",
    zIndex: 900
  }
};

export const legendDefaults = { show: true, bottom: 10 }

export const yAxisDefaults = {
  min: "dataMin",
  max: "dataMax",
  zlevel:0,
  axisLabel: {
    showMinLabel: false,
    showMaxLabel: false
  },
  animation: false,
  nameGap: 35,
  nameLocation: "center"
}

export const xAxisDefaults = {
  animation: false,
  nameLocation: "center"
}

export const seriesDefaults = {
  animation: false
}

export const marklineDefaults = {
  animation: false
}