import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import ComparisonCard from "./ComparisonCard/ComparisonCard";

function CompareData({ ticker }) {
  return (
    <>
      <Row>
        <Col lg={12}>
          <ComparisonCard ticker={ticker} />
        </Col>
      </Row>
    </>
  );
}

CompareData.propTypes = {
  ticker: PropTypes.string.isRequired
};

export default CompareData;
