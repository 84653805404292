import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Tabs, DefaultTabBar } from "rmc-tabs";
import classNames from "classnames";

import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Row,
  Col
} from "reactstrap";

import BasePage from "../../layout/page/BasePage";
import PageTitle from "../../layout/page/PageTitle";
import {
  getInstruments,
  getInstrumentsByCategory,
  getCorrelation
} from "../../../services/market";
// import Card from "../../layout/header/Card";

function Dashboard(props) {
  const [instruments, setInstruments] = useState([]);
  const [correlation, setCorrelation] = useState(null);

  useEffect(() => {
    getInstrumentsByCategory(4).then(data => setInstruments(data));
  }, []);
  useEffect(() => {
    getCorrelation("ytd_corr_forex_majors").then(data => setCorrelation(data));
  }, []);

  return (
    <BasePage>
      <PageTitle title="Dashboard" />
      <Row>
        <Col lg={5}>
          <Card>
            {" "}
            <div className="card-header pd-t-20 d-sm-flex align-items-start justify-content-between bd-b-0 pd-b-0">
              <div>
                <h6 className="mg-b-5">Performance</h6>
                <p className="tx-13 tx-color-03 mg-b-0">
                  Your sales and referral earnings over the last 30 days
                </p>
              </div>
            </div>
            {/* card-header */}
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-dashboard mg-b-0">
                  <thead>
                    <tr>
                      <th>Market</th>
                      <th className="text-right">Week</th>
                      <th className="text-right">Month</th>
                      <th className="text-right">Quarter</th>
                      <th className="text-right">Year</th>
                    </tr>
                  </thead>
                  <tbody>
                    {instruments.map(x => (
                      <tr>
                        <td className="tx-color-03">{x.name}</td>
                        <td
                          className={classNames(
                            "text-right tx-medium",
                            x.wtd_pct > 0 ? "tx-ms-blue" : "tx-pink"
                          )}
                        >
                          {x.wtd_pct}%
                        </td>
                        <td
                          className={classNames(
                            "text-right tx-medium",
                            x.mtd_pct > 0 ? "tx-ms-blue" : "tx-pink"
                          )}
                        >
                          {x.mtd_pct}%
                        </td>
                        <td
                          className={classNames(
                            "text-right tx-medium",
                            x.qtd_pct > 0 ? "tx-ms-blue" : "tx-pink"
                          )}
                        >
                          {x.qtd_pct}%
                        </td>
                        <td
                          className={classNames(
                            "text-right tx-medium",
                            x.ytd_pct > 0 ? "tx-ms-blue" : "tx-pink"
                          )}
                        >
                          {x.ytd_pct}%
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Card>
        </Col>
        <Col lg={7}>
          <Card>
            {" "}
            <div className="card-header pd-t-20 d-sm-flex align-items-start justify-content-between bd-b-0 pd-b-0">
              <div>
                <h6 className="mg-b-5">Correlations</h6>
                <p className="tx-13 tx-color-03 mg-b-0">
                  Your sales and referral earnings over the last 30 days
                </p>
              </div>
            </div>
            {/* card-header */}
            <div className="card-body">
              {correlation && (
                <div className="table-responsive">
                  <table className="table table-dashboard mg-b-0">
                    <thead>
                      <tr>
                        <th></th>
                        {correlation.data.index.map(x => (
                          <th>{x}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {correlation.data.values.map((row, ix) => (
                        <tr>
                          <td style={{ fontWeight: "500" }}>
                            {correlation.data.index[ix]}
                          </td>
                          {row.map(col => (
                            <td
                              className={classNames(
                                "text-right tx-medium",
                                col > 0 ? "tx-ms-blue" : "tx-pink"
                              )}
                            >
                              {col}%
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </BasePage>
  );
}

Dashboard.propTypes = {};

export default Dashboard;
