import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Card from "../../../../layout/card/Card";
import PerformanceTable from "./PerformanceTable";
import CardHeader from "../../../../layout/card/CardHeader";
import { getInstrumentsByCategory } from "../../../../../services/market";
import CardBody from "../../../../layout/card/CardBody";

function PerformanceCard({title, category}) {
  const [instruments, setInstruments] = useState([]);

  useEffect(() => {
    getInstrumentsByCategory(category).then(data => setInstruments(data));
  }, []);

  return (
    <Card>
      <CardHeader title={title} />
      <CardBody loading={!Boolean(instruments)}>
        {instruments && <PerformanceTable instruments={instruments} />}
      </CardBody>
    </Card>
  );
}

PerformanceCard.propTypes = {
    title: PropTypes.string.isRequired,
    category: PropTypes.number.isRequired
};

export default PerformanceCard;
