import { useState, useEffect } from "react";

// handles loading of data and loading state
// returns data and loading state

export function useApiCall(
  requestFunc,
  condition = undefined,
  initialLoadCondition = true,
  dataGetter = res => res.data
) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  let useEffectCondition = condition;
  if (condition === undefined) {
    useEffectCondition = initialLoadCondition;
  } else {
    useEffectCondition = [condition, initialLoadCondition];
  }

  const load = () => {
    setLoading(true);
    requestFunc().then(res => {
      const data = dataGetter(res);
      setData(data);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (initialLoadCondition) {
      load();
    }
  }, useEffectCondition);

  return [data, loading, load];
}
