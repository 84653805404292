import React from "react";
import PropTypes from "prop-types";
import {
  withRouter,
  Link,
  NavLink,
  useLocation,
  useRouteMatch,
  useParams,
} from "react-router-dom";
import { PieChart as PieChartIcon } from "react-feather";
import { default as CustomNavLink } from "./../NavLink";
import MarketSearchMini from "../../../ui/MarketSearchMini";
import Logo from "../../Logo";

function MobileNav(props) {
  console.log("proppies: ", props);

  const location = useLocation();
  const match = useRouteMatch();
  console.log("match: ", match);
  const params = useParams();
  console.log("params: ", params);

  const ticker = "foo";

  return (
    <div id="navbarMenu" className="navbar-menu-wrapper">
      <div className="navbar-menu-header">
        {/* <a href="../index.html" className="df-logo">
          dash<span>forrrge</span>
        </a> */}
        <span>&lt;- Back to Main Navigation</span>
        <a id="mainMenuClose" href="#">
          <i data-feather="x" />
        </a>
      </div>
      <ul className="nav navbar-menu">
        <li className="nav-label pd-l-15 pd-lg-l-25 d-lg-none">
          Search
          <MarketSearchMini />
        </li>
        <li className="nav-label pd-l-15 pd-lg-l-25 d-lg-none">
          {ticker} Navigation
        </li>
        <CustomNavLink text="Forum" to="https://forum.marketseasonals.com" />

        <NavLink
          onClick={() => document.body.classList.remove("navbar-nav-show")}
          to={`/market/${ticker}/`}
          className="nav-link"
          exact
        >
          <span>Overview</span>
        </NavLink>
        <NavLink to={`/market/${ticker}/years`} className="nav-link">
          <span>Years</span>
        </NavLink>
        <NavLink to={`/market/${ticker}/months`} className="nav-link">
          <span>Months</span>
        </NavLink>
        <NavLink to={`/market/${ticker}/weeks`} className="nav-link">
          <span>Weeks</span>
        </NavLink>
        <NavLink to={`/market/${ticker}/weekdays`} className="nav-link">
          <span>Weekdays</span>
        </NavLink>

        {/* <CustomNavLink text="Markets">
          {toggleSubNav => (
            <ul className="navbar-menu-sub">
              <li className="nav-sub-item" onClick={toggleSubNav}>
                <Link to="/market/forex" className="nav-sub-link">
                  <i data-feather="bar-chart-2" />
                  Forex
                </Link>
              </li>
              <li className="nav-sub-item" onClick={toggleSubNav}>
                <Link to="/market/indices" className="nav-sub-link">
                  <i data-feather="bar-chart-2" />
                  Indices
                </Link>
              </li>
              <li className="nav-sub-item">
                <a
                  href="template/classic/dashboard-three.html"
                  className="nav-sub-link"
                >
                  <i data-feather="bar-chart-2" />
                  Cryptocurrency
                </a>
              </li>
              <li className="nav-sub-item">
                <a
                  href="template/classic/dashboard-four.html"
                  className="nav-sub-link"
                >
                  <i data-feather="bar-chart-2" />
                  Helpdesk Management
                </a>
              </li>
            </ul>
          )}
        </CustomNavLink> */}

        {/* <CustomNavLink text="News Events" to="/news-events" /> */}

        {/* <CustomNavLink text="Backtester" to="/backtester" /> */}

        {/* <CustomNavLink text="Feature Requests" to="feature-requests" /> */}

        {/* <CustomNavLink text="Help" to="/help" /> */}
      </ul>
    </div>
  );
}

MobileNav.propTypes = {};

export default withRouter(MobileNav);
