import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Search } from "react-feather";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { toggleSearch } from "../../../redux/ducks/layout/actions";
import WithModal from "../../wrapper/WithModal";
import SignupModal from "../../modals/SignupModal";
import SignInModal from "../../modals/SignInModal/SignInModal";
import { logoutUser } from "../../../redux/ducks/account/actions";
import MarketSearchMini from "../../ui/MarketSearchMini";
import { account } from "../../../redux/ducks";

function RightNav(props) {
  let history = useHistory();

  const isWideEnough = useMediaQuery({
    query: "(min-device-width: 800px)",
  });

  return (
    <div className="navbar-right" style={{ width: "auto" }}>
      {isWideEnough && (
        <>
          <MarketSearchMini />

          {/* <Search /> */}
          <a
            id="navbarSearch"
            // onClick={() => props.toggleSearch(true)}
            className="search-link"
          >
            <Search />
          </a>
        </>
      )}

      {!props.account.loggedIn ? (
        <WithModal>
          {(modalActive, toggleModal) => (
            <>
              <button
                // onClick={toggleModal}
                onClick={() => history.push("/login")}
                className="btn btn-buy"
                style={{ border: 0 }}
              >
                <span>Login</span>
              </button>
              <SignInModal
                modalActive={modalActive}
                toggleModal={toggleModal}
              />
            </>
          )}
        </WithModal>
      ) : (
        <>
          {/* <button
          onClick={() => history.push("/account")}
          className="btn btn-buy"
          style={{ border: 0 }}
        >
          <span>{props.account.username}</span>
        </button> */}
          <button
            onClick={props.logoutUser}
            // onClick={() => history.push("/logout")}
            className="btn btn-buy"
            style={{ border: 0 }}
          >
            <span>Logout</span>
          </button>
        </>
      )}

      {/* {!props.account.loggedIn && (
        <WithModal>
          {(modalActive, toggleModal) => (
            <>
              <button
                href="#"
                // onClick={toggleModal}
                onClick={() => history.push("/register")}
                className="btn btn-buy"
              >
                <i data-feather="shopping-bag" /> <span>Sign up</span>
              </button>
              <SignupModal
                modalActive={modalActive}
                toggleModal={toggleModal}
              />
            </>
          )}
        </WithModal>
      )} */}
    </div>
  );
}

RightNav.propTypes = {
  toggleSearch: PropTypes.func, // provided by redux
};

const mapStateToProps = (state) => ({
  account: state.account,
});

export default connect(mapStateToProps, { toggleSearch, logoutUser })(RightNav);
