import React from "react";
import PropTypes from "prop-types";
import CardBody from "../../../../../../layout/card/CardBody";
import Card from "../../../../../../layout/card/Card";
import CurrentweekChart from "./CurrentWeekChart";

function CurrentWeekCard({ ticker }) {
  // todo: lift loading to this component
  return (
    <Card>
      <CardBody>
        <CurrentweekChart ticker={ticker} />
      </CardBody>
    </Card>
  );
}

CurrentWeekCard.propTypes = {};

export default CurrentWeekCard;
