import React from "react";
import PropTypes from "prop-types";
import { NavLink, Link } from "react-router-dom";

function Sidebar(props) {
  return (
    <div className="filemgr-sidebar">
      <div className="filemgr-sidebar-header"></div>
      <div className="filemgr-sidebar-body">
        <div className="pd-t-20 pd-b-10 pd-x-10">
          <label className="tx-sans tx-uppercase tx-medium tx-10 tx-spacing-1 tx-color-03 pd-l-10">
            Settings
          </label>
          <nav className="nav nav-sidebar tx-13">
            <NavLink to={"/account"} className="nav-link" exact>
              <span>Profile</span>
            </NavLink>
          </nav>
        </div>
        <div className="pd-10">
          <label className="tx-sans tx-uppercase tx-medium tx-10 tx-spacing-1 tx-color-03 pd-l-10">
            More Coming...
          </label>
          {/* <nav className="nav nav-sidebar tx-13">
            <NavLink
              to={`/market/${ticker}/custom-seasonality`}
              className="nav-link"
            >
              <span>Custom Seasonality</span>
            </NavLink>
            <NavLink
              to={`/market/${ticker}/custom-studies`}
              className="nav-link"
            >
              <span>Custom Studies</span>
            </NavLink>
            <NavLink to={`/market/${ticker}/patterns`} className="nav-link">
              <span>Pattern Matches</span>
            </NavLink>
            <NavLink to={`/market/${ticker}/chart`} className="nav-link">
              <span>Interactive Chart</span>
            </NavLink>
            <NavLink to={`/market/${ticker}/compare`} className="nav-link">
              <span>Compare</span>
            </NavLink>
          </nav> */}
        </div>
      </div>
    </div>
  );
}

Sidebar.propTypes = {};

export default Sidebar;
