import React from "react";
import PropTypes from "prop-types";
import ReactEcharts from "echarts-for-react";
import {
  legendDefaults,
  xAxisDefaults,
  yAxisDefaults,
  seriesDefaults,
  chartWatermark
} from "../../../../../../charts/constants";
import {
  toDate,
  convertToCategorySeries
} from "../../../../../../../utils/converter";

function WeeklyHighLowChart({ charts, currentHigh, currentLow }) {
  const stringFormat = "ddd HH:mm";
  const stringFormatCurrent = "ddd HH:00";

  const options = {
    legend: legendDefaults,
    title: {
      text: "Weekly Highs & Lows",
      subtext: "Weekly highs and lows histogram by time of the day."
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        animation: false
      }
    },
    xAxis: {
      ...xAxisDefaults,
      type: "category",
      data: charts[0].index.map(x => toDate(x, stringFormat))
    },
    yAxis: [
      {
        ...yAxisDefaults,
        name: "Percent",
        type: "value",
        splitLine: {
          show: false
        }
      }
    ],
    graphic: [chartWatermark],
    series: charts.map(chart => {
      const s = {
        ...seriesDefaults,
        name: chart.name,
        type: "bar",
        showSymbol: false,
        data: convertToCategorySeries(chart.x, chart.y, value =>
          toDate(value, stringFormat)
        )
      };
      if (chart.chart_type === "high_counts" && currentHigh) {
        s.markLine = {
          lineStyle: {
            type: "dashed"
          },
          label: {
            show: false
          },
          symbol: "circle",
          data: [{ xAxis: toDate(currentHigh, stringFormatCurrent) }]
        };
      } else if (chart.chart_type === "low_counts" && currentLow) {
        s.markLine = {
          lineStyle: {
            type: "dashed"
          },
          label: {
            show: false
          },
          symbol: "circle",
          data: [{ xAxis: toDate(currentLow, stringFormatCurrent) }]
        };
      }
      return s;
    })
  };

  return (
    <ReactEcharts
      option={options}
      style={{ height: "400px" }}
      theme={"theme"}
    />
  );
}

WeeklyHighLowChart.propTypes = {
  charts: PropTypes.object.isRequired,
  currentHigh: PropTypes.string,
  currentLow: PropTypes.string
};

export default WeeklyHighLowChart;
