import React from "react";
import PropTypes from "prop-types";
import { Link, NavLink, useLocation } from "react-router-dom";
import { PieChart as PieChartIcon } from "react-feather";
import { useMediaQuery } from "react-responsive";

import { default as CustomNavLink } from "./../NavLink";
import MarketSearchMini from "../../../ui/MarketSearchMini";
import Logo from "../../Logo";
import MobileNav from "./MobileNav";

function Nav({ toggleSearch }) {
  const isWideEnough = useMediaQuery({
    query: "(min-width: 992px)",
  });

  // console.log("IS WIDE ENOUGH? ", isWideEnough)

  // if(!isWideEnough){
  //   return <MobileNav />
  // }

  return (
    <div id="navbarMenu" className="navbar-menu-wrapper">
      <div className="navbar-menu-header">
        {/* <a href="../index.html" className="df-logo">
          dash<span>forrrge</span>
        </a> */}
        <Logo />
        <a id="mainMenuClose" href="#">
          <i data-feather="x" />
        </a>
      </div>
      <ul className="nav navbar-menu">
        <li className="nav-label pd-l-15 pd-lg-l-25 d-lg-none">
          Search
          <MarketSearchMini />
        </li>
        <li className="nav-label pd-l-15 pd-lg-l-25 d-lg-none">
          Main Navigation
        </li>
        <CustomNavLink
          text="Forum"
          onClick={() => document.body.classList.remove("navbar-nav-show")}
          to="https://forum.marketseasonals.com"
        />

        {/* <CustomNavLink text="Markets">
          {toggleSubNav => (
            <ul className="navbar-menu-sub">
              <li className="nav-sub-item" onClick={toggleSubNav}>
                <Link to="/market/forex" className="nav-sub-link">
                  <i data-feather="bar-chart-2" />
                  Forex
                </Link>
              </li>
              <li className="nav-sub-item" onClick={toggleSubNav}>
                <Link to="/market/indices" className="nav-sub-link">
                  <i data-feather="bar-chart-2" />
                  Indices
                </Link>
              </li>
              <li className="nav-sub-item">
                <a
                  href="template/classic/dashboard-three.html"
                  className="nav-sub-link"
                >
                  <i data-feather="bar-chart-2" />
                  Cryptocurrency
                </a>
              </li>
              <li className="nav-sub-item">
                <a
                  href="template/classic/dashboard-four.html"
                  className="nav-sub-link"
                >
                  <i data-feather="bar-chart-2" />
                  Helpdesk Management
                </a>
              </li>
            </ul>
          )}
        </CustomNavLink> */}

        {/* <CustomNavLink text="News Events" to="/news-events" /> */}

        {/* <CustomNavLink text="Backtester" to="/backtester" /> */}

        {/* <CustomNavLink text="Feature Requests" to="feature-requests" /> */}

        {/* <CustomNavLink text="Help" to="/help" /> */}
      </ul>
    </div>
  );
}

Nav.propTypes = {};

export default Nav;
