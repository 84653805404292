import React from "react";
import PropTypes from "prop-types";
import _ from "underscore";
import { useApiCall } from "../../../../../../hooks/useApiCall";
import { getCharts } from "../../../../../../services/market";
import Card from "../../../../../layout/card/Card";
import CardBody from "../../../../../layout/card/CardBody";
import HoursChart from "./HoursChart";

function HoursCard({ ticker }) {
  const hours = _.range(24);
  const chartTypes = hours.map((x) => `hour_perf_${x}`);
  const loadData = () => getCharts(ticker, chartTypes);

  const [data, loading] = useApiCall(loadData, ticker, true, (res) => res);

  return (
    <Card>
      <CardBody loading={loading}>
        {data && <HoursChart data={data} />}
      </CardBody>
    </Card>
  );
}

HoursCard.propTypes = {
  ticker: PropTypes.string.isRequired,
};

export default HoursCard;
