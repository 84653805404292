import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Card from "../../../../../../layout/card/Card";
import CardBody from "../../../../../../layout/card/CardBody";
import {
  getCharts,
  getCurrentWeekPeaks
} from "../../../../../../../services/market";
import WeeklyHighLowChart from "./WeeklyHighLowChart";

function WeeklyHighLowCard({ ticker }) {
  const [loading, setLoading] = useState(true);
  const [charts, setCharts] = useState(null);
  const [currentPeaks, setCurrentPeaks] = useState();

  // update chart data when ticker changes
  useEffect(() => {
    setLoading(true);
    getCharts(ticker, ["low_counts", "high_counts"]).then(data => {
      setCharts(data);
      setLoading(false);
    });
  }, [ticker]);

  useEffect(() => {
    getCurrentWeekPeaks(ticker).then(data => setCurrentPeaks(data));
  }, [ticker]);

  return (
    <Card>
      <CardBody loading={loading}>
        <WeeklyHighLowChart
          charts={charts}
          currentHigh={currentPeaks && currentPeaks.high.datetime}
          currentLow={currentPeaks && currentPeaks.low.datetime}
        />
      </CardBody>
    </Card>
  );
}

WeeklyHighLowCard.propTypes = {
  ticker: PropTypes.string.isRequired
};

export default WeeklyHighLowCard;
