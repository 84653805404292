import React from "react";
import PropTypes from "prop-types";
import ReactEcharts from "echarts-for-react";
import moment from "moment";
import { chartWatermark } from "../../../../../../charts/constants";

function PatternContextChart({ data }) {
  if (!data) {
    return "Loading...";
  }

  const chartData = data.y.map(x => {
    return [+x[0], +x[3], +x[2], +x[1]];
  });

  const dates = data.x.map(x =>
    moment
      .tz(new Date(x / 1000 / 1000), "Europe/Berlin")
      .utc()
      //   .format("YYYY-MM-DD")
      .format("GGGG-WW")
  );

  const options = {
    legend: { show: true, bottom: 10 },
    title: {
      //   text: "Current Week",
      //   subtext: "Average Weekly Highs and Lows"
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        animation: false
      }
    },
    xAxis: {
      type: "category",
      data: dates,
      animation: false
    },
    yAxis: [
      {
        scale: true,
        axisLabel: {
          showMinLabel: false,
          showMaxLabel: false
        },
        animation: false
      }
    ],
    dataZoom: [
      {
        textStyle: {
          color: "#8392A5"
        },
        handleIcon:
          "M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
        handleSize: "80%",
        dataBackground: {
          areaStyle: {
            color: "#8392A5"
          },
          lineStyle: {
            opacity: 0.8,
            color: "#8392A5"
          }
        },
        handleStyle: {
          color: "#fff",
          shadowBlur: 3,
          shadowColor: "rgba(0, 0, 0, 0.6)",
          shadowOffsetX: 2,
          shadowOffsetY: 2
        }
      },
      {
        type: "inside"
      }
    ],
    graphic: [chartWatermark],
    series: [
      {
        name: "Current Week",
        type: "candlestick",
        showSymbol: false,
        data: chartData,
        animation: false
      }
    ]
  };

  return (
    <ReactEcharts
      option={options}
      style={{ height: "400px" }}
      theme={"theme"}
    />
  );
}

PatternContextChart.propTypes = {
  data: PropTypes.object.isRequired
};

export default PatternContextChart;
