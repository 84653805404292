import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import Datetime from "react-datetime";

function DateRange({ onChange, dates, timeFormat }) {
  return (
    <Row>
      <Col lg={6}>
        <Datetime
          dateFormat="MM-DD"
          timeFormat={timeFormat}
          value={dates.fromDate}
          onChange={(date) => onChange({ fromDate: date })}
          closeOnSelect
        />
      </Col>
      <Col lg={6}>
        <Datetime
          dateFormat="MM-DD"
          timeFormat={timeFormat}
          value={dates.toDate}
          onChange={(date) => onChange({ toDate: date })}
          isValidDate={(selectedDate) => {
            if (!dates.fromDate) {
              return true;
            }
            return selectedDate.isAfter(dates.fromDate)
          }}
          closeOnSelect
        />
      </Col>
    </Row>
  );
}

DateRange.defaultProps = {
  timeFormat: false,
};
DateRange.propTypes = {
  onChange: PropTypes.func.isRequired,
  dates: PropTypes.object.isRequired,
};

export default DateRange;
