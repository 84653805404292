import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Col, Row, FormGroup, Label, Input, Button } from "reactstrap";
import { animateScroll } from "react-scroll";
import Card from "../../../../layout/card/Card";
import CardBody from "../../../../layout/card/CardBody";
import CardHeader from "../../../../layout/card/CardHeader";
import ResultChart from "./ResultChart";

function CustomStudies({ ticker }) {
  const [code, setCode] = useState("");
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);

  const scrollToBottom = () => {
    // animateScroll.scrollTo("result-container", {
    //   duration: 150,
    //   containerId: "main-content",
    // });
    animateScroll.scrollToBottom({
      duration: 150,
      containerId: "main-content",
    });
  };

  const onSubmit = (optionalCode=null) => {
    setResult(null);
    setLoading(true);

    const params = {
      ticker: ticker,
      code: optionalCode || code,
    };

    axios.post("/api/custom-study", params).then((res) => {
      setLoading(false);
      setResult(res.data);
    });

    // scrollToBottom();
  };

  useEffect(() => {
    if (result || loading) {
      scrollToBottom();
    }
  }, [result, loading]);

  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            {/* <CardHeader title="Custom Study" /> */}

            <CardBody>
              <Row>
                <Col lg={12}>
                  <p>
                    Analyze patterns and special market conditions by entering
                    custom conditions in the code box below. You can write
                    multiple conditions separated by a new line. Conditions are
                    always written as comparisons.
                  </p>
                  <p>
                    Currently there are 3 things you can compare with eachother:
                    <br />
                    Attributes (<code>close</code>, <code>high</code> etc.),
                    Functions (<code>average()</code>, <code>lowest()</code>)
                    and Numbers (<code>1</code>, <code>1.05</code> etc.)
                  </p>
                  <strong>Attributes</strong>
                  <p>
                    Attributes are simple market data points like the open,
                    high, low and close. For a full list of attributes see
                    below.
                  </p>
                  <strong>Functions</strong>
                  <p>
                    Functions take 2 arguments and return a value. The first
                    argument is the reference column and the second argument is
                    the time window. If you want the 200 period moving average
                    of the closing price you would write:{" "}
                    <code>average(close, 200)</code>. <br />
                    For the full list of available functions see below.
                  </p>
                  <p>
                    Attributes and functions can also be accessed in the history
                    by appending,
                    <code>[n]</code> where n equals the periods to be shifted
                    backwards. For example yesterdays low can be accessed by{" "}
                    <code>low[1]</code>.
                  </p>
                  <strong>Example #1</strong>
                  <p>
                    Get all days which broke out to a new 50-day high but closed
                    below yesterdays low. The code would look like this:
                  </p>
                  <pre>
                    {"high > highest(high, 50) \n"}
                    {"close < low[1]"}
                  </pre>

                  <Button
                    size="xs"
                    onClick={() => {
                      const value = "high > highest(high, 50) \nclose < low[1]"
                      setCode(value);
                      onSubmit(value);
                    }}
                  >
                    Run Example #1
                  </Button>
                  <br />
                  <br />
                  <strong>Example #2</strong>
                  <p>Get all Mondays which closed down by more than 1%.</p>
                  <pre>
                    {"weekday = 1 \n"}
                    {"change < 0.99"}
                  </pre>

                  <Button
                    size="xs"
                    onClick={() => {
                      const value = "weekday = 1 \nchange < 0.99" 
                      setCode(value);
                      onSubmit(value);
                    }}
                  >
                    Run Example #2
                  </Button>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col lg={6}>
                  Available Attributes
                  <ul>
                    <li>
                      <code>open</code>: Opening price of candle
                    </li>
                    <li>
                      <code>high</code>: High of candle
                    </li>
                    <li>
                      <code>low</code>: Low of candle
                    </li>
                    <li>
                      <code>close</code>: Closing price of candle
                    </li>
                    <li>
                      <code>change</code>: Percentage change of candle body (+1%
                      equals 1.01, -2% equals 0.98)
                    </li>
                    <li>
                      <code>weekday</code>: Weekday Number (where Monday = 1)
                    </li>
                    <li>
                      <code>month</code>: Month Number (where April = 4)
                    </li>
                  </ul>
                </Col>
                <Col lg={6}>
                  Available Functions
                  <ul>
                    <li>
                      <code>average(reference, period)</code>: <br />
                      reference := attribute from above
                      <br />
                      period := number of candles
                      <br />
                      <code>average(close, 200)</code>
                    </li>
                    <li>
                      <code>highest(reference, period)</code>: <br />
                      reference := attribute from above
                      <br />
                      period := number of candles
                      <br />
                      <code>highest(high, 20)</code>
                    </li>
                    <li>
                      <code>lowest(reference, period)</code>: <br />
                      reference := attribute from above
                      <br />
                      period := number of candles
                      <br />
                      <code>lowest(low, 20)</code>
                    </li>
                  </ul>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col lg={6}>
                  <FormGroup>
                    <Label for="exampleText">Code</Label>
                    <Input
                      type="textarea"
                      name="text"
                      onChange={(e) => setCode(e.target.value)}
                      value={code}
                      rows={10}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Button
                color="primary"
                onClick={() => onSubmit()}
                style={{ float: "right" }}
                disabled={!code.trim()}
              >
                Run Calculations
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
        <div id="result-container">
          {(result || loading) && (
            <Row>
              <Col lg={12}>
                <Card>
                  <CardHeader title="Result" />
                  <CardBody loading={loading}>
                    {result && <ResultChart ticker={ticker} data={result} />}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
    </>
  );
}

CustomStudies.propTypes = {
  ticker: PropTypes.string,
};

export default CustomStudies;
