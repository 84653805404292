import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import Card from '../../../../../layout/card/Card';
import CardBody from '../../../../../layout/card/CardBody';

function RelatedInstrumentsCard({ticker, instruments}) {

    let currentInstrument
    let relatedInstruments = []

    // if(instruments){
    //     currentInstrument = instruments.find(x => x.ticker === ticker)
    //     relatedInstruments = instruments.filter(x =>  (x.numerator === currentInstrument.numerator || x.denominator === currentInstrument.numerator))
    // }

    return (
        <Card>
<CardBody>

        <div>
            {relatedInstruments.map(x => (
                <p>{x.ticker}</p>
            ))}
        </div>
</CardBody>
        </Card>
    )
}

RelatedInstrumentsCard.propTypes = {

}

const mapStateToProps = state => ({
    instruments: state.market.instruments
})

export default connect(mapStateToProps, {})(RelatedInstrumentsCard)

