import React from "react";
import PropTypes from "prop-types";

import BasePage from "../../layout/page/BasePage";
import PageTitle from "../../layout/page/PageTitle";
import Footer from "../../layout/Footer";
import { useHistory } from "react-router";
import landingImage from "../../../images/landing-page.png";


function Landing(props) {
  const history = useHistory();

  return (
    <>
      <div
        className="content content-fixed"
        style={{ minHeight: "calc(100vh - 106px)" }}
      >
        <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
          <div className="row home-slider">
            <div className="col-lg-12 col-xs-3" style={{ textAlign: "center" }}>
            <img src={landingImage} className="img-fluid" style={{width: "30%"}} alt="" />

              <h2
                style={{
                  fontSize: "60px",
                  fontWeight: "bold",
                  marginTop: "50px",
                }}
              >
                Know your market
              </h2>
              <h3>Professional market research tool</h3>
              <p>
                We are currently in a closed beta. If you are interested in
                participating in the beta get in touch on{" "}
                <a href="https://twitter.com/quantgorilla">twitter</a>.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

Landing.propTypes = {};

export default Landing;
