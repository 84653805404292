import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import WeekdayAveragesCard from "./cards/WeekdayAveragesCard/WeekdayAveragesCard";
import WeekdayPerformanceCard from "./cards/WeekdayPerformanceCard/WeekdayPerformanceCard";


function Weekdays({ ticker }) {
  return (
    <>
      <Row>
        <Col lg={6}>
            <WeekdayAveragesCard ticker={ticker} />
          </Col>
          <Col lg={6}>
            <WeekdayPerformanceCard ticker={ticker} />
          </Col>
      </Row>
    </>
  );
}

Weekdays.propTypes = {};

export default Weekdays;
