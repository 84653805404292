import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { getLastWeeks, getCharts } from "../../../../../../../services/market";
import { dataObjectToDate, toDate } from "../../../../../../../utils/converter";
import Card from "../../../../../../layout/card/Card";
import CardBody from "../../../../../../layout/card/CardBody";
import WeeklyVolatilityChart from "./WeeklyVolatilityChart";

function WeeklyVolatilityCard({ ticker }) {
  const [loading, setLoading] = useState(true);
  const [charts, setCharts] = useState([]);

  // update chart data when ticker changes
  useEffect(() => {
    setLoading(true);
    getCharts(ticker, ["weekly_volatility"]).then(data => {
      setCharts(data);
      setLoading(false);
    });
  }, [ticker]);

  return (
    <Card>
      <CardBody loading={loading}>
        <WeeklyVolatilityChart volatility={charts[0]} />
      </CardBody>
    </Card>
  );
}

WeeklyVolatilityCard.propTypes = {
  ticker: PropTypes.string.isRequired
};

export default WeeklyVolatilityCard;
