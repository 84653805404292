import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Autosuggest from "react-autosuggest";
import { toggleSearch } from "../../../../redux/ducks/layout/actions";
import { Search as SearchIcon, X as XIcon } from "react-feather";
import Backdrop from "../../Backdrop";
import SearchInput from "./SearchInput";
import SearchItem from "./SearchItem";

// todo: move searchActive out of redux!!
function SearchBar({ searchActive, toggleSearch, instruments }) {
  let className = "navbar-search";
  if (searchActive) {
    className += " visible";
  }

  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const onChange = e => setInputValue(e.target.value);

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const inputProps = {
    placeholder: "Type a programming language",
    value: inputValue,
    onChange: onChange,
    toggleSearch: toggleSearch,
    type: "search"
  };

  const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : instruments.filter(
          i => i.name.toLowerCase().slice(0, inputLength) === inputValue
        );
  };

  return (
    <>
      <div className={className}>
        {/* <div className="navbar-search-header">
          <input
            type="search"
            className="form-control"
            placeholder="Type and hit enter to search..."
            style={{textTransform: "uppercase"}}
          />
          <button className="btn">
            <SearchIcon />
          </button>
          <button
            className="btn mg-l-5 mg-lg-l-10"
            onClick={() => toggleSearch(false)}
          >
            <XIcon />
          </button>
        </div> */}

        <Autosuggest
          // suggestions={instruments || []}
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={suggestion => suggestion.name}
          renderInputComponent={SearchInput}
          renderSuggestion={SearchItem}
          // renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          shouldRenderSuggestions={() => true}
          alwaysRenderSuggestions
        />

        <div className="navbar-search-body">
          <label className="tx-10 tx-medium tx-uppercase tx-spacing-1 tx-color-03 mg-b-10 d-flex align-items-center">
            Recent Searches
          </label>
          <ul className="list-unstyled">
            <li>
              <a href="dashboard-one.html">Euro / US Dollar (EUR/USD)</a>
            </li>
            <li>
              <a href="app-calendar.html">calendar app</a>
            </li>
            <li>
              <a href="../../collections/modal.html">modal examples</a>
            </li>
            <li>
              <a href="../../components/el-avatar.html">avatar</a>
            </li>
          </ul>
          <hr className="mg-y-30 bd-0" />
          <label className="tx-10 tx-medium tx-uppercase tx-spacing-1 tx-color-03 mg-b-10 d-flex align-items-center">
            Results
          </label>
          <ul className="search-results">
            <li>
              <a href="dashboard-one.html">Euro / US Dollar (EUR/USD)</a>
            </li>
            <li>
              <a href="app-calendar.html">Euro / Japanese Yen (EUR/JPY)</a>
            </li>
            <li>
              <a href="../../collections/modal.html">
                Euro / Australian Dollar (EUR/AUD)
              </a>
            </li>
            <li>
              <a href="../../components/el-avatar.html">contact app</a>
            </li>
          </ul>
        </div>
      </div>
      {/* <Backdrop active={searchActive} toggle={() => {
        toggleSearch(false)
        document.body.classList.remove("navbar-nav-show")
        }} /> */}
    </>
  );
}

SearchBar.propTypes = {
  searchActive: PropTypes.bool.isRequired,
  toggleSearch: PropTypes.func.isRequired,
  instruments: PropTypes.array
};

const mapStateToProps = state => ({
  searchActive: state.layout.searchActive,
  instruments: state.market.instruments
});

export default connect(
  mapStateToProps,
  { toggleSearch }
)(SearchBar);
