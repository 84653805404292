import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";
import TradingViewWidget from "react-tradingview-widget";

function InteractiveChart({ ticker }) {
  return (
    <>
      <Row>
        <Col lg={12}>
          <div style={{ height: "70vh" }}>
            <TradingViewWidget symbol={`OANDA:${ticker}`} autosize />
          </div>
        </Col>
      </Row>
    </>
  );
}

InteractiveChart.propTypes = {
  ticker: PropTypes.string
};

export default InteractiveChart;
