import React from "react";
import PropTypes from "prop-types";
import ReactEcharts from "echarts-for-react";
import { legendDefaults, xAxisDefaults, yAxisDefaults, chartWatermark, seriesDefaults } from "../../../../../charts/constants";
import { convertToCategorySeries, toDate } from "../../../../../../utils/converter";

function SessionsChart({ data }) {
  const options = {
      legend: legendDefaults,
      title: {
          text: "Cumulative Performance by Session",
          subtext: "US Session: 16.30-22.30  /  Europe Session:  7:00-16:00  /  Asia Session: 0:00-9:00 (UTC time)"
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          animation: false
        }
      },
      xAxis: {
          ...xAxisDefaults,
          type: "time"
      },
      yAxis: {
          ...yAxisDefaults,
          name: "Percent",
          type: "value",
          splitLine: {
            show: false
          }
      },
      series: data.map(s => ({
          ...seriesDefaults,
          name: s.name,
          type: "line",
          showSymbol: false,
          data: convertToCategorySeries(s.x, s.y, value => toDate(value))
      })),
      graphic: [chartWatermark]
  };

  return (
    <ReactEcharts
      option={options}
      style={{ height: "400px" }}
      theme={"theme"}
    />
  );
}

SessionsChart.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SessionsChart;
