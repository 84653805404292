import React from "react";
import PropTypes from "prop-types";
import ReactEcharts from "echarts-for-react";
import moment from "moment"
import _ from "underscore";

import {
  legendDefaults,
  xAxisDefaults,
  yAxisDefaults,
  seriesDefaults,
  chartWatermark
} from "../../../../../../charts/constants";
import { toDate } from "../../../../../../../utils/converter";

function MonthlyPerformanceChart({ performances }) {

  const currentMonth = moment().month()
  let selectedSeries = _.range(12).map(x => ({
    [moment().month(x).format("MMM")]: x === currentMonth ? true : false
  }));
  selectedSeries = Object.assign(...selectedSeries)

  const options = {
    legend: {
      ...legendDefaults,
      selected: selectedSeries,
    },
    title: {
      text: "Monthly performances",
      subtext: "Cumulative performance of each month."
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        animation: false
      }
    },

    xAxis: {
      ...xAxisDefaults,
      type: "category",
      data: performances[0].x.map(x => toDate(x, "D"))
    },
    yAxis: [
      {
        ...yAxisDefaults,
        name: "Percent",
        type: "value",
        splitLine: {
          show: false
        }
      }
    ],
    graphic: [chartWatermark],
    series: performances.map((data, ix) => ({
      ...seriesDefaults,
      name: moment().month(ix).format("MMM"),
      type: "line",
      showSymbol: false,
      data: data.y
    }))
  };

  return (
    <ReactEcharts
      option={options}
      style={{ height: "400px" }}
      theme={"theme"}
    />
  );
}

MonthlyPerformanceChart.propTypes = {};

export default MonthlyPerformanceChart;
