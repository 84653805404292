import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getLastYears } from "../../../../../../../services/market";
import Card from "../../../../../../layout/card/Card";
import CardBody from "../../../../../../layout/card/CardBody";
import LastYearsChart from "./LastYearsChart";

function LastYearsCard({ ticker }) {
  const [loading, setLoading] = useState(true);
  const [charts, setCharts] = useState(null);

  // update chart data when ticker changes
  useEffect(() => {
    setLoading(true);
    getLastYears(ticker).then(data => {
      setCharts(data);
      setLoading(false);
    });
  }, [ticker]);

  return (
    <Card>
      <CardBody loading={loading}>
        <LastYearsChart lastYears={charts} />
      </CardBody>
    </Card>
  );
}

LastYearsCard.propTypes = {
  ticker: PropTypes.string.isRequired
};

export default LastYearsCard;
