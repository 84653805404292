import React from 'react'
import PropTypes from 'prop-types'
import { Search as SearchIcon, X as XIcon } from "react-feather";

function SearchInput(props) {
    return (
        <div className="navbar-search-header">
          <input
            // {...props}
            type="search"
            className="form-control"
            placeholder="Type and hit enter to search..."
            style={{textTransform: "uppercase"}}
          />
          <button className="btn">
            <SearchIcon />
          </button>
          <button
            className="btn mg-l-5 mg-lg-l-10"
            onClick={() => props.toggleSearch(false)}
          >
            <XIcon />
          </button>
        </div>
    )
}

SearchInput.propTypes = {
  toggleSearch: PropTypes.func.isRequired,
}

export default SearchInput

