import React, { Component } from "react";
import Select from "react-select";
import { FormGroup, Input, Label, Button, Col, Row } from "reactstrap";

export default class CriteriaSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
        id: this.props.id,
      paramLeft: null,
      argLeft: "",
      attrLeft: "",
      paramRight: null,
      argRight: "",
      attrRight: "",
      operator: null,
      ruleCompleted:false
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    console.log(e);
  }

  render() {
    const { operators, parameters } = this.props;

    let ruleComplete = true;
    if (this.state.paramLeft) {
      if (this.state.paramLeft.argument === true) {
        if (!this.state.argLeft) {
          ruleComplete = false;
        }
      }
      if (this.state.paramLeft.attribute === true) {
        if (!this.state.attrLeft) {
          ruleComplete = false;
        }
      }
    } else {
      ruleComplete = false;
    }
    if (this.state.paramRight) {
      if (this.state.paramRight.argument === true) {
        if (!this.state.argRight) {
          ruleComplete = false;
        }
      }
      if (this.state.paramRight.attribute === true) {
        if (!this.state.attrRight) {
          ruleComplete = false;
        }
      }
    } else {
      ruleComplete = false;
    }

    if (ruleComplete) {
      if (!this.state.ruleCompleted) {
        const rule = this.state;
        this.props.onRuleComplete(rule);
        this.setState({ruleCompleted: true})
        console.log("RULE COMPLETE: ", rule);
      }
    }

    return (
      <React.Fragment>
        <Col lg={2}>
          <Select
            options={parameters}
            getOptionLabel={option => option.accessor}
            getOptionValue={option => option}
            onChange={x => this.setState({ paramLeft: x, ruleCompleted: false })}
          />
        </Col>
        {this.state.paramLeft && this.state.paramLeft.attribute === true && (
          <Col lg={2}>
            <Input
              type="text"
              name="attrLeft"
              placeholder="Bars Ago"
              onChange={e => this.setState({ attrLeft: e.target.value, ruleCompleted: false })}
              value={this.state.attrLeft}
            />
          </Col>
        )}
        {this.state.paramLeft && this.state.paramLeft.argument === true && (
          <Col lg={2}>
            <Input
              type="text"
              name="argLeft"
              onChange={e => this.setState({ argLeft: e.target.value, ruleCompleted: false })}
              value={this.state.argLeft}
            />
          </Col>
        )}
        <Col lg={2}>
          <Select
            options={operators}
            placeholder="Operator"
            // getOptionLabel={option => option}
            // getOptionValue={option => console.log(option)}
            onChange={x => this.setState({ operator: x, ruleCompleted: false })}
          />
        </Col>
        <Col lg={2}>
          <Select
            options={parameters}
            getOptionLabel={option => option.accessor}
            getOptionValue={option => option}
            onChange={x => this.setState({ paramRight: x, ruleCompleted: false })}
          />
        </Col>
        {this.state.paramRight && this.state.paramRight.attribute === true && (
          <Col lg={2}>
            <Input
              type="text"
              name="attrRight"
              placeholder="Bars Ago"
              onChange={e => this.setState({ attrRight: e.target.value, ruleCompleted: false })}
              value={this.state.attrRight}
            />
          </Col>
        )}
        {this.state.paramRight && this.state.paramRight.argument === true && (
          <Col lg={2}>
            <Input
              type="text"
              name="argRight"
              onChange={e => this.setState({ argRight: e.target.value, ruleCompleted: false })}
              value={this.state.argRight}
            />
          </Col>
        )}
      </React.Fragment>
    );
  }
}
