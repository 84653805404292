import React from "react";
import PropTypes from "prop-types";
import ReactEcharts from "echarts-for-react";
import {
  legendDefaults,
  xAxisDefaults,
  yAxisDefaults,
  seriesDefaults,
  chartWatermark
} from "../../../../../../charts/constants";
import {
  toDate,
  convertToCategorySeries
} from "../../../../../../../utils/converter";

function YearlyVolatilityChart({ volatility }) {
  const options = {
    legend: legendDefaults,
    title: {
      text: "Yearly Volatility",
      subtext: "Average weekly volatility based on the range of the candles."
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        animation: false
      }
    },
    xAxis: {
      ...xAxisDefaults,
      type: "category",
      data: volatility.x.map(x => toDate(x, "MM-DD"))
    },
    yAxis: [
      {
        ...yAxisDefaults,
        name: "Percent",
        type: "value",
        splitLine: {
          show: false
        }
      }
    ],
    graphic: [chartWatermark],
    series: {
      ...seriesDefaults,
      name: "Volatility",
      type: "line",
      showSymbol: false,
      // data: volatility.y,
      data: convertToCategorySeries(volatility.x, volatility.y, value =>
        toDate(value, "MM-DD")
      ),
      areaStyle: {}
    }
  };

  return (
    <ReactEcharts
      option={options}
      style={{ height: "400px" }}
      theme={"theme"}
    />
  );
}

YearlyVolatilityChart.propTypes = {
  volatility: PropTypes.object.isRequired
};

export default YearlyVolatilityChart;
