import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Redirect, useLocation } from "react-router-dom";
import { checkLogin } from "../../redux/ducks/account/actions";
import Loading from "../layout/Loading";

function PrivateRoute({
  children,
  loggedIn,
  loadingUser,
  checkLogin,
  ...rest
}) {
  //   const shouldLoad = !loggedIn;
  //   const [loading, setLoading] = useState(shouldLoad);

  //   useEffect(() => {
  //     if (!loadingUser && loading) {
  //       setLoading(false);
  //     }
  //   }, [loadingUser]);

  const location = useLocation();

  useEffect(() => {
    if (!loggedIn) {
      checkLogin();
    }
  }, [loggedIn]);

  if (loadingUser) {
    return (
      <div style={{ height: "50%" }}>
        <Loading />
      </div>
    );
  }

  if (loggedIn) {
    return <Route {...rest} />;
  } else {
    console.log("redirecting to: ", location.pathname + location.search);
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location.pathname + location.search },
        }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  loggedIn: state.account.loggedIn,
  loadingUser: state.account.loadingUser,
});

export default connect(mapStateToProps, { checkLogin })(PrivateRoute);
