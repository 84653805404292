import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Tabs, DefaultTabBar } from "rmc-tabs";
import ReactEcharts from "echarts-for-react";
import moment from 'moment'
import BasePage from "../../layout/page/BasePage";
import PageTitle from "../../layout/page/PageTitle";
import { getEventData } from "../../../services/market";
import {
  xAxisDefaults,
  yAxisDefaults,
  seriesDefaults,
  chartWatermark,
  legendDefaults
} from "../../charts/constants";

function NewsEventsDetail(props) {
  const eventId = props.match.params.id;
  const [eventData, setEventData] = useState([]);

  useEffect(() => {
    getEventData(eventId).then(data => setEventData(data));
  }, [eventId]);

  // useEffect(() => {
  //   getEventData().then(data => setEvents(data));
  // }, []);

  const options = {
    legend: legendDefaults,
    title: {
      text: "Weekday Performance",
      subtext: "Cumulative Performance of Weekdays"
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        animation: false
      }
    },
    xAxis: {
      ...xAxisDefaults,
      type: 'time',
      // data: eventData.map(x => moment(x.datetime).toDate())
    },
    yAxis: [
      {
        ...yAxisDefaults,
        name: "Percent",
        type: "value",
        min: "dataMin",
        axisLabel: {
          showMinLabel: false,
          showMaxLabel: false
        },
        splitLine: {
          show: false
        }
      }
    ],
    series: [{
      ...seriesDefaults,
      data: eventData.map(x => [moment(x.datetime).toDate(), x.actual]),
      type: 'bar'
    }],
    graphic: [chartWatermark]
  };

  return (
    <BasePage>
      <PageTitle title="News Events" />

      {eventData && (
        <ReactEcharts
          option={options}
          style={{ height: "350px" }}
          theme={"theme"}
        />
      )}

    </BasePage>
  );
}

NewsEventsDetail.propTypes = {};

export default NewsEventsDetail;
