import React from "react";
import PropTypes from "prop-types";
import ReactEcharts from "echarts-for-react";
import moment from "moment";
import {
  legendDefaults,
  xAxisDefaults,
  yAxisDefaults,
  chartWatermark,
  seriesDefaults,
} from "../../../../charts/constants";
import {
  convertToCategorySeries,
  toDate,
} from "../../../../../utils/converter";

function ResultChart({ data, ticker }) {
  const options = {
    legend: legendDefaults,
    title: {
      subtext: data.query,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        animation: false,
      },
    },
    xAxis: {
      ...xAxisDefaults,
      type: "category",
      data: data.x.map((value) => toDate(value / 1e6, "YYYY-MM-DD")),
    },
    yAxis: [
      {
        ...yAxisDefaults,
        name: "Price",
        type: "value",
        splitline: {
          show: false,
        },
      },
    ],
    graphics: [chartWatermark],

    grid: {
      bottom: 80,
    },
    dataZoom: [
      {
        textStyle: {
          color: "#8392A5",
        },
        handleIcon:
          "M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
        handleSize: "80%",
        dataBackground: {
          areaStyle: {
            color: "#8392A5",
          },
          lineStyle: {
            opacity: 0.8,
            color: "#8392A5",
          },
        },
        handleStyle: {
          color: "#fff",
          shadowBlur: 3,
          shadowColor: "rgba(0, 0, 0, 0.6)",
          shadowOffsetX: 2,
          shadowOffsetY: 2,
        },
      },
      {
        type: "inside",
      },
    ],

    series: [
      {
        ...seriesDefaults,
        name: ticker,
        type: "candlestick",
        // type: "line",
        showSymbol: false,
        // data: convertToCategorySeries(data.x, data.y, (value) => toDate(value/1e6, "YYYY-MM-DD")),
        data: data.y,

        markPoint: {
          // label: {
          //     normal: {
          //         formatter: function (param) {
          //             return param != null ? Math.round(param.value) : '';
          //         }
          //     }
          // },

          data: data.markers
            .map(([x, y]) => [toDate(x / 1e6, "YYYY-MM-DD"), y])
            .map(([x, y]) => ({
              // name: 'XX标点',
              coord: [x, y],
              value: "",
              itemStyle: {
                color: "rgb(69, 209, 107)",
              },
              symbol: "triangle",
              symbolRotate: 180,
              symbolSize: 9,
              symbolOffset: [0, '-75%']
            })),

          // tooltip: {
          //     formatter: function (param) {
          //         return param.name + '<br>' + (param.data.coord || '');
          //     }
          // }
        },
      },
    ],
  };

  return (
    <ReactEcharts
      option={options}
      style={{ height: "400px" }}
      theme={"theme"}
    />
  );
}

ResultChart.propTypes = {
  data: PropTypes.object.isRequired,
  ticker: PropTypes.string.isRequired,
};

export default ResultChart;
