import React from "react";
import PropTypes from "prop-types";
import ReactEcharts from "echarts-for-react";
import moment from "moment";

import { chartWatermark } from "../../../../../../charts/constants";
import { toDate } from "../../../../../../../utils/converter";


function CurrentYearChart({ thisYear, stats }) {

  const data = thisYear.y.map(x => {
    return [+x[0], +x[3], +x[2], +x[1]];
  });

  const dates = thisYear.x.map(x => toDate(x/1000/1000, "MM-DD"))

  const options = {
    legend: { show: true, bottom: 10 },
    title: {
      text: "Current Year",
      subtext: "Average Yearly Highs and Lows"
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        animation: false
      }
    },
    xAxis: {
      type: "category",
      data: dates,
      animation: false
    },
    yAxis: [
      {
        min: Math.min(
          data[0][0] * stats[0].data.year_q75_low * 0.997,
          Math.min(...data.map(x => x[2]))
        ),
        max: Math.max(
          data[0][0] * stats[0].data.year_q75_high * 1.003,
          Math.max(...data.map(x => x[3]))
        ),
        // max: data[0][0] * stats[0].data.year_q75_high * 1.003,
        axisLabel: {
          showMinLabel: false,
          showMaxLabel: false
        },
        animation: false
      }
    ],
    graphic: [chartWatermark],
    series: [
      {
        name: "Current Year",
        type: "candlestick",
        showSymbol: false,
        data: data,
        animation: false,
        markLine: {
          label: {
            formatter: "{b}: \n{c}"
          },
          precision: 4,
          data: [
            {
              name: "average high",
              yAxis: thisYear.y[0][0] * stats[0].data.year_avg_high
            },
            {
              name: "average low",
              yAxis: thisYear.y[0][0] * stats[0].data.year_avg_low
            },
            {
              name: "75% Quantile Highs",
              yAxis: thisYear.y[0][0] * stats[0].data.year_q75_high
            },
            {
              name: "75% Quantile Lows",
              yAxis: thisYear.y[0][0] * stats[0].data.year_q75_low
            }
          ],
          animation: false
        }
      }
    ]
  };

  return (
    <ReactEcharts
      option={options}
      style={{ height: "400px" }}
      theme={"theme"}
    />
  );
}

CurrentYearChart.propTypes = {
  thisYear: PropTypes.object.isRequired,
  stats: PropTypes.object.isRequired
};

export default CurrentYearChart;
