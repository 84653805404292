import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Card } from "reactstrap";
import {connect} from "react-redux"
import classNames from "classnames";

function RelatedPerformanceCard(props) {

  const {activeInstruments, instrument} = props

  if(!activeInstruments || !instrument){
    return null
  }

  const instruments = activeInstruments.filter(x => x.numerator === instrument.denominator || x.denominator === instrument.denominator)

  return (
    <Card>
      <div className="card-header pd-t-20 d-sm-flex align-items-start justify-content-between bd-b-0 pd-b-0">
        <div>
          <h6 className="mg-b-5">Performance</h6>
          <p className="tx-13 tx-color-03 mg-b-0">
            Your sales and referral earnings over the last 30 days
          </p>
        </div>
      </div>
      {/* card-header */}
      <div className="card-body">
        <div className="table-responsive">
          <table className="table table-dashboard mg-b-0">
            <thead>
              <tr>
                <th>Market</th>
                <th className="text-right">Week</th>
                <th className="text-right">Month</th>
                <th className="text-right">Quarter</th>
                <th className="text-right">Year</th>
              </tr>
            </thead>
            <tbody>
              {instruments.map(x => (
                <tr>
                  <td className="tx-color-03">{x.name}</td>
                  <td
                    className={classNames(
                      "text-right tx-medium",
                      x.wtd_pct > 0 ? "tx-ms-blue" : "tx-pink"
                    )}
                  >
                    {x.wtd_pct}%
                  </td>
                  <td
                    className={classNames(
                      "text-right tx-medium",
                      x.mtd_pct > 0 ? "tx-ms-blue" : "tx-pink"
                    )}
                  >
                    {x.mtd_pct}%
                  </td>
                  <td
                    className={classNames(
                      "text-right tx-medium",
                      x.qtd_pct > 0 ? "tx-ms-blue" : "tx-pink"
                    )}
                  >
                    {x.qtd_pct}%
                  </td>
                  <td
                    className={classNames(
                      "text-right tx-medium",
                      x.ytd_pct > 0 ? "tx-ms-blue" : "tx-pink"
                    )}
                  >
                    {x.ytd_pct}%
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Card>
  );
}

RelatedPerformanceCard.propTypes = {};

const mapStateToProps = state => ({
  activeInstruments: state.market.instruments
})

export default connect(mapStateToProps, {})(RelatedPerformanceCard);
