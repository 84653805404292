import React from "react";
import PropTypes from "prop-types";

function Backdrop({ active, toggle, hidden }) {
  const classes = active ? "backdrop show" : "backdrop";

  return (
    <div
      className={classes}
      style={hidden && { opacity: 0 }}
      onClick={toggle}
    />
  );
}

Backdrop.propTypes = {
  active: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
};

export default Backdrop;
