import axios from "axios";

export const getInstrumentsByCategory = category =>
  axios
    .get("/api/instruments", { params: { category: category } })
    .then(res => res.data);

export const getInstruments = tickers =>
  axios
    .get("/api/instruments", { params: { tickers: tickers } })
    .then(res => res.data);

export const getActiveInstruments = () =>
  axios.get("/api/instruments").then(res => res.data);

export const getEvents = () => axios.get("/api/events").then(res => res.data);

export const getEventData = id =>
  axios.get(`/api/events/${id}`).then(res => res.data);

export const getNews = ticker =>
  axios.get(`/api/instruments/${ticker}/news`).then(res => res.data);

export const getCharts = (ticker, chartTypes) =>
  axios
    .post("/api/charts", { ticker: ticker, chart_types: chartTypes })
    .then(res => res.data);

export const getStats = (ticker, statTypes) =>
  axios
    .post("/api/stats", { ticker: ticker, stat_types: statTypes })
    .then(res => res.data);

export const getCorrelation = type =>
  axios.post("/api/correlation", { type: type }).then(res => res.data);

export const getHistoricalData = data =>
  axios.post("/api/historical-data", data).then(res => res.data);

export const getSentimentData = data =>
  axios.post("/api/sentiment-data", data).then(res => res.data);

export const getOrderbookData = data =>
  axios.post("/api/orderbook", data).then(res => res.data);

// old implementation
// export const getCurrentPeaks = data =>
//   axios.post("/api/current-peaks", data).then(res => res.data);

export const getLastWeeks = ticker =>
  axios.get(`/api/instruments/${ticker}/weeks/last`).then(res => res.data);

export const getLastYears = ticker =>
  axios.get(`/api/instruments/${ticker}/years/last`).then(res => res.data);

export const getPatterns = (ticker, timeframe, nMatches = 5) =>
  axios
    .get(`/api/instruments/${ticker}/pattern/${timeframe}`, {
      params: {
        n_matches: nMatches
      }
    })
    .then(res => res.data);

export const getCurrentWeekPeaks = ticker =>
  axios
    .get(`/api/instruments/${ticker}/weeks/current-peaks`)
    .then(res => res.data);

export const getComparisons = (ticker, data) =>
  axios.post(`/api/instruments/${ticker}/compare`, data).then(res => res.data);
