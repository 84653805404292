import React from "react";
import PropTypes from "prop-types";
import { Tabs, DefaultTabBar } from "rmc-tabs";

import BasePage from "../../layout/page/BasePage";
import PageTitle from "../../layout/page/PageTitle";

function MarketOverview(props) {
  return (
    <BasePage>
      <PageTitle title="Euro / US Dollar (EUR/USD)" />
      <Tabs
        tabs={[
          { key: "t1", title: "Overview" },
          { key: "t2", title: "Year" },
          { key: "t3", title: "Month" },
          { key: "t4", title: "Week" },
          { key: "t5", title: "Weekday" },
          { key: "t6", title: "Pattern Matches" },
          { key: "t7", title: "News Events" },
          { key: "t8", title: "Peak Analysis" }
        ]}
        initalPage={"t2"}
        usePaged
        renderTabBar={props => <DefaultTabBar {...props} />}
      >
        <div key="t1">
          <p>content1</p>
        </div>
        <div key="t2">
          <p>content2</p>
        </div>
        <div key="t3">
          <p>content3</p>
        </div>
        <div key="t4">
          <p>content4</p>
        </div>
        <div key="t5">
          <p>content5</p>
        </div>
      </Tabs>
    </BasePage>
  );
}

MarketOverview.propTypes = {};

export default MarketOverview;
