import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Tabs, DefaultTabBar } from "rmc-tabs";

import BasePage from "../../layout/page/BasePage";
import PageTitle from "../../layout/page/PageTitle";
import { getEvents } from "../../../services/market";


function NewsEvents(props) {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    getEvents().then(data => setEvents(data));
  }, []);



  return (
    <BasePage>
      <PageTitle title="News Events" />
      {events &&
        events.map(x => (
          <Link to={`/news-events/${x.id}`}>
            <p>
              {x.name} <span>{x.importance}</span>
            </p>
          </Link>
        ))}
    </BasePage>
  );
}

NewsEvents.propTypes = {};

export default NewsEvents;
