import React from "react";
import PropTypes from "prop-types";
import { getHistoricalData } from "../../../../../../../services/market";
import { useApiCall } from "../../../../../../../hooks/useApiCall";
import CardBody from "../../../../../../layout/card/CardBody";
import Card from "../../../../../../layout/card/Card";
import PatternContextChart from "./PatternContextChart";

function PatternContextCard({ ticker }) {
  const loadData = () =>
    getHistoricalData({
      ticker: ticker,
      resolution: "W",
      candlestick: true
    });

  const [data, loading] = useApiCall(loadData, ticker, true);
  console.log("data: ", data);

  return (
    <Card>
      <CardBody>
        <PatternContextChart data={data} />
      </CardBody>
    </Card>
  );
}

PatternContextCard.propTypes = {
  ticker: PropTypes.string.isRequired
};

export default PatternContextCard;
