import React from 'react'
import PropTypes from 'prop-types'
import {withRouter} from "react-router-dom"

function SearchItem(props, { query, isHighlighted }) {
    return (
        <div style={{color: "black", background: "white"}} onClick={() => props.history.push(`/market/${props.ticker}`)}>
            {props.name}
        </div>
    )
}

SearchItem.propTypes = {

}

export default withRouter(SearchItem)

