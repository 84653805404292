import React from "react";
import PropTypes from "prop-types";

function PerformanceLabel({ value, text }) {
  return (
    <p
      className="tx-11 tx-color-03 mg-b-0 mg-l-20 inline"
      style={{ fontWeight: "normal" }}
    >
      <span
        className={value > 0 ? "tx-medium tx-success" : "tx-medium tx-danger"}
      >
        {value} %
        <i
          className={
            value > 0 ? "icon ion-md-arrow-up" : "icon ion-md-arrow-down"
          }
        ></i>
      </span>{" "}
      {text}
    </p>
  );
}

PerformanceLabel.propTypes = {
  value: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired
};

export default PerformanceLabel;
