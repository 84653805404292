import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactEcharts from "echarts-for-react";
import moment from "moment";

import { chartWatermark } from "../../../../../../charts/constants";
import { getHistoricalData, getStats } from "../../../../../../../services/market";


function CurrentweekChart({ ticker }) {
  const [stats, setStats] = useState(null);
  const [thisWeek, setThisWeek] = useState(null);

  const historicalDataParams = {
    ticker: ticker,
    start_datetime: moment()
      .startOf("isoweek") // attention with timezone?!
      .tz("Europe/Berlin")
      .subtract(2, "hour")
      .utc()
      .valueOf(),
    resolution: "1H",
    candlestick: true
  };

  useEffect(() => {
    getHistoricalData(historicalDataParams).then(data =>
      setThisWeek(data.data)
    );
    getStats(ticker, ["week"]).then(data => setStats(data));
  }, [ticker]);

  if (!thisWeek || !stats) {
    return null;
  }

  if (thisWeek.y.length === 0) {
    return "No data available.";
  } 

  const data = thisWeek.y.map(x => {
    return [+x[0], +x[3], +x[2], +x[1]];
  });

  const dates = thisWeek.x.map(x =>
    moment
      .tz(new Date(x / 1000 / 1000), "Europe/Berlin")
      .utc()
      .format("ddd HH:mm")
  );

  const options = {
    legend: { show: true, bottom: 10 },
    title: {
      text: "Current Week",
      subtext: "Average Weekly Highs and Lows"
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        animation: false
      }
    },
    xAxis: {
      type: "category",
      data: dates,
      animation: false
    },
    yAxis: [
      {
        min: Math.min(
          data[0][0] * stats[0].data.week_q75_low * 0.997,
          Math.min(...data.map(x => x[2]))
        ),
        max: Math.max(
          data[0][0] * stats[0].data.week_q75_high * 1.003,
          Math.max(...data.map(x => x[3]))
        ),
        // max: data[0][0] * stats[0].data.week_q75_high * 1.003,
        axisLabel: {
          showMinLabel: false,
          showMaxLabel: false
        },
        animation: false
      }
    ],
    graphic: [chartWatermark],
    series: [
      {
        name: "Current Week",
        type: "candlestick",
        showSymbol: false,
        data: data,
        animation: false,
        markLine: {
          label: {
            formatter: "{b}: \n{c}"
          },
          precision: 4,
          data: [
            {
              name: "average high",
              yAxis: thisWeek.y[0][0] * stats[0].data.week_avg_high
            },
            {
              name: "average low",
              yAxis: thisWeek.y[0][0] * stats[0].data.week_avg_low
            },
            {
              name: "75% Quantile Highs",
              yAxis: thisWeek.y[0][0] * stats[0].data.week_q75_high
            },
            {
              name: "75% Quantile Lows",
              yAxis: thisWeek.y[0][0] * stats[0].data.week_q75_low
            }
          ],
          animation: false
        }
      }
    ]
  };

  return (
    <ReactEcharts
      option={options}
      style={{ height: "400px" }}
      theme={"theme"}
    />
  );
}

CurrentweekChart.propTypes = {
  ticker: PropTypes.string.isRequired
};

export default CurrentweekChart;
