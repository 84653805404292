import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody, TabContent, TabPane } from "reactstrap";
import { loginUser } from "../../../redux/ducks/account/actions";
import LoginTab from "./LoginTab";

function SignInModal(props) {
  const { modalActive, toggleModal } = props;

  const [activeTab, setActiveTab] = useState("login");

  return (
    <Modal
      isOpen={modalActive}
      toggle={toggleModal}
      className="wd-sm-400"
      autoFocus={false}
      returnFocusAfterClose={false}
      centered
    >
      <ModalBody className="pd-20 pd-sm-40">
        <TabContent activeTab={activeTab}>
          <TabPane tabId="login">
            <LoginTab setActiveTab={setActiveTab} toggleModal={toggleModal} />
          </TabPane>
          <TabPane tabId="forgotPassword">
            <div>
              <a
                onClick={toggleModal}
                role="button"
                className="close pos-absolute t-15 r-15"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </a>
              <div>
                <h4>Forgot Password</h4>
                <p className="tx-color-03">
                  You can reset your password below.
                </p>
                <div className="form-group">
                  <label>Email address</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="yourname@yourmail.com"
                  />
                </div>
                <div className="form-group">
                  <div className="d-flex justify-content-between mg-b-5">
                    <label className="mg-b-0-f">Password</label>
                    <a href className="tx-13">
                      Forgot password?
                    </a>
                  </div>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Enter your password"
                  />
                </div>
                <button className="btn btn-primary btn-block">Sign In</button>
                <div className="tx-13 mg-t-20 tx-center">
                  Don't have an account?{" "}
                  <a href="page-signup.html">Create an Account</a>
                </div>
              </div>
            </div>
          </TabPane>
        </TabContent>
      </ModalBody>
    </Modal>
  );
}

SignInModal.propTypes = {};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(
  mapStateToProps,
  { loginUser }
)(SignInModal);
