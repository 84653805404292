import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

function Logo(props) {
  return (
    <Link to="/" className="df-logo" style={{ fontSize: "20px" }}>
      MARKET<span>SEASONALS</span>
    </Link>
  );
}

Logo.propTypes = {};

export default Logo;
