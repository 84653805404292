export const LOGIN_USER = "account/LOGIN_USER"
export const LOGIN_USER_PENDING = "account/LOGIN_USER_PENDING"
export const LOGIN_USER_FULFILLED = "account/LOGIN_USER_FULFILLED"
export const LOGIN_USER_REJECTED = "account/LOGIN_USER_REJECTED"

export const LOGOUT_USER = "account/LOGOUT_USER"
export const LOGOUT_USER_PENDING = "account/LOGOUT_USER_PENDING"
export const LOGOUT_USER_FULFILLED = "account/LOGOUT_USER_FULFILLED"
export const LOGOUT_USER_REJECTED = "account/LOGOUT_USER_REJECTED"

export const CHECK_LOGIN = "account/CHECK_LOGIN"
export const CHECK_LOGIN_PENDING = "account/CHECK_LOGIN_PENDING"
export const CHECK_LOGIN_FULFILLED = "account/CHECK_LOGIN_FULFILLED"
export const CHECK_LOGIN_REJECTED = "account/CHECK_LOGIN_REJECTED"

export const SET_USER = "account/SET_USER"
