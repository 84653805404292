import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { Input, Button } from "reactstrap";
import queryString from "query-string";

import axios from "axios";
import Loading from "../../layout/Loading";

function VerifyAccount(props) {
  const queryParams = props.location.search;

  const { user_id, timestamp, signature } = queryString.parse(
    props.location.search
  );

  //   const userId = props.match.params.user_id
  //   const timestamp = props.match.params.timestamp
  //   const signature = props.match.params.signature

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user_id && timestamp && signature) {
      const params = {
        user_id: user_id,
        timestamp: timestamp,
        signature: signature,
      };
      axios.post("/api/accounts/verify-registration/", params).then(() => {
        setLoading(false);
      });
    }
  }, []);

  return (
    <div className="content content-fixed content-auth">
      <div className="container">
        <div className="media align-items-stretch justify-content-center ht-100p">
          <div className="sign-wrapper mg-lg-r-50 mg-xl-r-60">
            <div className="pd-t-20 wd-100p" style={{ textAlign: "center" }}>
              <div style={{ width: "35%", margin: "40px auto" }}>
                <svg viewBox="0 0 98.5 98.5">
                  <path
                    className="svg-checkmark"
                    fill="none"
                    d="M81.7 17.8C73.5 9.3 62 4 49.2 4 24.3 4 4 24.3 4 49.2s20.3 45.2 45.2 45.2 45.2-20.3 45.2-45.2c0-8.6-2.4-16.6-6.5-23.4L45.6 68.2 24.7 47.3"
                  />
                </svg>
              </div>
              <h4 className="tx-color-01 mg-b-5">
                {loading ? "Verifying Email..." : "Welcome!"}
              </h4>
              {loading ? (
                <Loading />
              ) : (
                <>
                  <p>Account successfully verfied!</p>

                  <Button>Login now</Button>
                </>
              )}
            </div>
          </div>
          {/* sign-wrapper */}
        </div>
        {/* media */}
      </div>
      {/* container */}
    </div>
  );
}

VerifyAccount.propTypes = {};

export default VerifyAccount;
