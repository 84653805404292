import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import axios from "axios";
import echarts from "echarts";
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./redux/store";

import { Button } from "reactstrap";

import "./css/dashforge.css";
import "./css/dashforge.filemgr.css";
import "./css/marketseasonals.css";
import "./css/react-datetime.css";
import "rmc-tabs/assets/index.css";
import theme from "./components/charts/theme";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
echarts.registerTheme("theme", theme);

const instance = createInstance({
  urlBase: "https://marketseasonals.matomo.cloud/",
  siteId: 1,
});

ReactDOM.render(
  <MatomoProvider value={instance}>
    <ReduxProvider store={configureStore()}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ReduxProvider>
  </MatomoProvider>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
