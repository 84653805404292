import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

function SignupModal(props) {
  const { modalActive, toggleModal } = props;

  return (
    <Modal
      isOpen={modalActive}
      toggle={toggleModal}
      returnFocusAfterClose={false}
      centered
    >
      <ModalBody className="pd-20 pd-sm-40">
        <div>
          <h4>Create New Account</h4>
          <p className="tx-color-03">
            It's free to signup and only takes a minute.
          </p>
          <div className="form-group">
            <label>Full Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Full name"
            />
          </div>
          <div className="form-group">
            <label>Email address</label>
            <input
              type="email"
              className="form-control"
              placeholder="yourname@yourmail.com"
            />
          </div>
          <div className="form-group">
            <label className="mg-b-0-f">Password</label>
            <input
              type="password"
              className="form-control"
              placeholder="Enter your password"
            />
          </div>
          <div className="form-group">
            By clicking Create an account below, you agree to our terms of
            service and privacy statement.
          </div>
          <button className="btn btn-primary btn-block">Create Account</button>
          <div className="tx-13 mg-t-20 tx-center">
            Already have an account? <a href="page-signup.html">Sign In</a>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

SignupModal.propTypes = {};

export default SignupModal;
