import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { Input, Button, FormFeedback } from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";

function Register(props) {
  // const initialValues = {
  //   username: "",
  //   email: "",
  //   password: ""
  // }

  // const [values, setValues] = useState(initialValues)
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [username, setUsername] = useState({ value: "", valid: undefined });
  const [email, setEmail] = useState({ value: "", valid: undefined });
  const [password, setPassword] = useState({ value: "" });
  const [passwordConfirm, setPasswordConfirm] = useState({
    value: "",
  });

  // useEffect(() => {
  //   if(username.value){
  //     axios.post("/api/account/availability", {type: "username", })
  //   }
  // },[username.value])

  // useEffect(() => {
  //   for (const [ key, value ] of Object.entries(dictionary)) {

  // }
  // }, [errors]
  // )

  const formIsValid = () => {
    let isValid = true;
    if (
      !(
        username.valid &&
        email.valid &&
        password.value &&
        passwordConfirm.value
      )
    ) {
      isValid = false;
    }
    return isValid;
  };

  const validate = (field, value) => {
    if (value) {
      // if (field === "password") {
      //   setPassword({ ...password, valid: true });
      //   return;
      // }

      // if (field == "passwordConfirm") {
      //   setPasswordConfirm({
      //     ...passwordConfirm,
      //     valid: password.value === passwordConfirm.value,
      //   });
      //   return;
      // }

      let validInput = false;
      if (field == "username") {
        validInput = /^[a-zA-Z0-9]+$/.test(value);
      } else if (field == "email") {
        validInput = /.+@.+\.[A-Za-z]+$/.test(value);
      }

      axios
        .post("/api/account/availability/", { field: field, value: value })
        .then((res) => {
          if (field === "username") {
            setUsername({
              ...username,
              valid: res.data.available && validInput,
            });
          } else if (field === "email") {
            setEmail({ ...email, valid: res.data.available && validInput });
          }
        });
    }
  };

  const onSubmit = () => {
    setIsSubmitting(true);
    const params = {
      username: username.value,
      email: email.value,
      password: password.value,
      password_confirm: passwordConfirm.value,
    };
    axios
      .post("/api/accounts/register/", params)
      .then(() => {
        setIsSubmitting(false);
        const encodedEmail = btoa(email.value);
        props.history.push(`/thank-you/${encodedEmail}`);
      })
      .catch((e) => {
        console.log("error: ", e.response);
        console.log("error data:", e.response.data);
        setErrors(e.response.data);
        setIsSubmitting(false);
      });
  };

  return (
    <div className="content content-fixed content-auth">
      <div className="container">
        <div className="media align-items-stretch justify-content-center ht-100p">
          <div className="sign-wrapper mg-lg-r-50 mg-xl-r-60">
            <div className="pd-t-20 wd-100p">
              <h4 className="tx-color-01 mg-b-5">Welcome, Beta Tester!</h4>
              <p className="tx-color-03 tx-16 mg-b-40">
                {/* It's free to signup and only takes a minute. */}
                You're only a minute away from getting started.
              </p>
              <div className="form-group">
                <label>Username</label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Enter your username"
                  value={username.value}
                  onChange={(e) =>
                    setUsername({ ...username, value: e.target.value })
                  }
                  onBlur={(e) => validate("username", e.target.value)}
                  valid={username.valid}
                  invalid={username.valid === false}
                />
              </div>
              <div className="form-group">
                <label>Email address</label>
                <Input
                  type="email"
                  className="form-control"
                  placeholder="Enter your email address"
                  value={email.value}
                  onChange={(e) =>
                    setEmail({ ...email, value: e.target.value })
                  }
                  onBlur={(e) => validate("email", e.target.value)}
                  valid={email.valid}
                  invalid={email.valid === false}
                />
              </div>
              <div className="form-group">
                <div className="d-flex justify-content-between mg-b-5">
                  <label className="mg-b-0-f">Password</label>
                </div>
                <Input
                  type="password"
                  className="form-control"
                  placeholder="Enter your password"
                  value={password.value}
                  onChange={(e) => {
                    setPassword({ ...password, value: e.target.value });
                    if (errors.password) {
                      setErrors({ ...errors, password: undefined });
                    }
                  }}
                  // onBlur={(e) => validate("password", e.target.value)}
                  // valid={password.valid && !errors.password}
                  invalid={errors.password}
                />
                {errors.password &&
                  errors.password.map((x) => <FormFeedback>{x}</FormFeedback>)}
              </div>
              <div className="form-group">
                <div className="d-flex justify-content-between mg-b-5">
                  <label className="mg-b-0-f">Confirm Password</label>
                </div>
                <Input
                  type="password"
                  className="form-control"
                  placeholder="Enter your password"
                  value={passwordConfirm.value}
                  onChange={(e) => {
                    setPasswordConfirm({
                      ...passwordConfirm,
                      value: e.target.value,
                    });
                    if (errors.password_confirm) {
                      setErrors({ ...errors, password_confirm: undefined });
                    }
                  }}
                  // onBlur={(e) => validate("passwordConfirm", e.target.value)}
                  // valid={passwordConfirm.valid}
                  invalid={errors.password_confirm}
                />
                {errors.password_confirm &&
                  errors.password_confirm.map((x) => (
                    <FormFeedback>{x}</FormFeedback>
                  ))}
              </div>
              <div className="form-group tx-12">
                By clicking <strong>Create an account</strong> below, you agree
                to our terms of service and privacy statement.
              </div>
              {/* form-group */}
              <Button
                className="btn btn-brand-02 btn-block"
                disabled={!formIsValid() || isSubmitting}
                onClick={onSubmit}
              >
                Create Account
              </Button>
              <div className="tx-13 mg-t-20 tx-center">
                Already have an account? <Link to="/login">Sign In</Link>
              </div>
            </div>
          </div>
          {/* sign-wrapper */}
        </div>
        {/* media */}
      </div>
      {/* container */}
    </div>
  );
}

Register.propTypes = {};

export default Register;
