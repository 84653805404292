import React from "react";
import PropTypes from "prop-types";
import ReactEcharts from "echarts-for-react";
import {
  legendDefaults,
  xAxisDefaults,
  yAxisDefaults,
  chartWatermark,
  seriesDefaults,
} from "../../../../../charts/constants";
import {
  convertToCategorySeries,
  toDate,
} from "../../../../../../utils/converter";

function HoursChart({ data }) {
  const options = {
    legend: {
      ...legendDefaults,
      type: "scroll",
      // orient: "vertical",
      // bottom: "auto",
    },
    title: {
      text: "Cumulative Performance by Hour of Day",
    },
    xAxis: {
      ...xAxisDefaults,
      type: "time",
    },
    yAxis: {
      ...yAxisDefaults,
      name: "Percent",
      type: "value",
      splitLine: {
        show: false,
      },
    },
    series: data.map((s) => ({
      ...seriesDefaults,
      name: s.name,
      type: "line",
      showSymbol: false,
      data: convertToCategorySeries(s.x, s.y, (value) => toDate(value)),
    })),
    graphic: [chartWatermark],
  };

  return (
    <ReactEcharts
      option={options}
      style={{ height: "400px" }}
      theme={"theme"}
    />
  );
}

HoursChart.propTypes = {
  data: PropTypes.object.isRequired,
};

export default HoursChart;
