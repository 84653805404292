import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";

function MarketSelector({ instruments, value, onChange }) {
  return (
    <Select
      options={instruments}
      getOptionLabel={option => option.name}
      getOptionValue={option => option.ticker}
      value={value}
      onChange={onChange}
    />
  );
}

MarketSelector.propTypes = {
  instruments: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object
};

const mapStateToProps = state => ({
  instruments: state.market.instruments
});

export default connect(mapStateToProps, {})(MarketSelector);
