import * as types from "./types";
import * as services from "./services";

export const toggleSearch = show => ({
  type: types.TOGGLE_SEARCH,
  payload: show
});

export const toggleBackdrop = show => ({
  type: types.TOGGLE_BACKDROP,
  payload: show
});

export const toggleMarketNav = show => ({
  type: types.TOGGLE_MARKET_NAV,
  payload: show
});