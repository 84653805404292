import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Formik } from "formik";
import { loginUser } from "../../../redux/ducks/account/actions";

function LoginTab(props) {
  const { setActiveTab, user, toggleModal, loginUser } = props;

  useEffect(() => {
    if (user.loggedIn) {
      toggleModal();
    }
  }, [user.loggedIn]);

  return (
    <div>
      <a
        href
        role="button"
        className="close pos-absolute t-15 r-15"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">×</span>
      </a>
      <div>
        <h4>Sign In</h4>
        <p className="tx-color-03">Welcome back! Please signin to continue.</p>

        <Formik
          initialValues={{ email: "", password: "" }}
          validate={values => {
            let errors = {};
            if (!values.email) {
              errors.email = "Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            loginUser(values.email, values.password);
          }}
        >
          {formik => (
            <>
              <div className="form-group">
                <label>Email address</label>
                <input
                  type="email"
                  name="email"
                  onChange={formik.handleChange}
                  className="form-control"
                  placeholder="yourname@yourmail.com"
                />
              </div>
              <div className="form-group">
                <div className="d-flex justify-content-between mg-b-5">
                  <label className="mg-b-0-f">Password</label>
                  <a
                    className="tx-13"
                    onClick={() => setActiveTab("forgotPassword")}
                  >
                    Forgot password?
                  </a>
                </div>
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  placeholder="Enter your password"
                  onChange={formik.handleChange}
                />
              </div>
              <button
                className="btn btn-primary btn-block"
                onClick={formik.handleSubmit}
              >
                Sign In
              </button>
              <div className="tx-13 mg-t-20 tx-center">
                Don't have an account?{" "}
                <a href="page-signup.html">Create an Account</a>
              </div>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
}

LoginTab.propTypes = {
  setActiveTab: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: state.account
});

export default connect(
  mapStateToProps,
  { loginUser }
)(LoginTab);
