import * as types from "./types";

const initialState = {
  username: null,
  email: null,
  loggedIn: false,
  isPremium: false,
  loadingUser: true,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.LOGIN_USER_FULFILLED:
      return {
        ...state,
        loggedIn: true,
        loadingUser: false,
        email: action.payload.email,
        username: action.payload.username,
      };

    case types.SET_USER:
        return { ...state, ...action.payload, loggedIn: true };

    case types.LOGOUT_USER_FULFILLED:
      return { ...state, loggedIn: false };

    case types.CHECK_LOGIN_PENDING:
      return { ...state, loadingUser: true };

    case types.CHECK_LOGIN_FULFILLED:
      return {
        ...state,
        loggedIn: true,
        loadingUser: false,
        email: action.payload.email,
        username: action.payload.username,
      };

    case types.CHECK_LOGIN_REJECTED:
      return {...initialState, loadingUser: false};

    default:
      return state;
  }
}
