import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import SessionsCard from "./SessionsCard/SessionsCard";
import HoursCard from "./HoursCard/HoursCard";

function TimeOfDay({ ticker }) {
  return (
    <>
      <Row>
        <Col lg={12}>
          <SessionsCard ticker={ticker} />
        </Col>
        <Col lg={12}>
          <HoursCard ticker={ticker} />
        </Col>
      </Row>
    </>
  );
}

TimeOfDay.propTypes = {
  ticker: PropTypes.object.isRequired,
};

export default TimeOfDay;
