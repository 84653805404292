import React from "react";
import PropTypes from "prop-types";
import { Heart as HeartIcon, Info as InfoIcon } from "react-feather";
import ContentLoader, { Facebook } from "react-content-loader";


function PageTitle(props) {
  return (
    <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
      {props.title ? (
        <div>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
              <li className="breadcrumb-item">
                <a href="dashboard-two.html#">Markets</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Forex
              </li>
            </ol>
          </nav>
          <h4 className="mg-b-0 tx-spacing--1">{props.title}</h4>
        </div>
      ) : (
        <div style={{width: "300px"}}>
        <ContentLoader
          height={60}
          width={300}
          speed={1}
          primaryColor="#fff"
          secondaryColor="#fbfbfb"
        >
          <rect x="0" y="0" rx="5" ry="5" width="144" height="9" />
          <rect x="0" y="24" rx="5" ry="5" width="299" height="19" />
        </ContentLoader></div>
      )}

      <div className="d-none d-md-block">
        <button className="btn btn-sm pd-x-15 btn-white btn-uppercase">
          <InfoIcon className="wd-10 mg-r-5" /> Info
        </button>
        {/* <button className="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5">
          <i data-feather="upload" className="wd-10 mg-r-5" /> Export
        </button>
        <button className="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5">
          <i data-feather="share-2" className="wd-10 mg-r-5" /> Share
        </button> */}
        <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
          <HeartIcon className="wd-10 mg-r-10 filled" />
          Add to Favorites
        </button>
      </div>
    </div>
  );
}

PageTitle.propTypes = {};

export default PageTitle;
