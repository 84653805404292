import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Datetime from "react-datetime";
import { MinusCircle as MinusIcon } from "react-feather";

import MarketSelector from "../../../../../ui/MarketSelector";
import { Row, Col } from "reactstrap";

function ComparisonInput({
  index,
  ticker,
  date,
  instruments,
  remove,
  onChange
}) {
  const [selectedMarket, setSelectedMarket] = useState(null);

  useEffect(() => {
    if (instruments) {
      const initialMarket = instruments.find(
        x => x.ticker.toLowerCase() === ticker.toLowerCase()
      );
      setSelectedMarket(initialMarket);
    }
  }, [ticker, instruments]);

  const getInstrument = ticker =>
    instruments.find(x => x.ticker.toLowerCase() === ticker.toLowerCase());

  return (
    <Row style={{ marginBottom: "10px" }}>
      <Col lg={1} style={{ textAlign: "center" }}>
        {index > 0 && (
          <MinusIcon
            onClick={remove}
            style={{ marginTop: "7px", cursor: "pointer" }}
          />
        )}
      </Col>
      <Col lg={6}>
        <MarketSelector
          onChange={market => onChange({ ticker: market.ticker, date: date })}
          value={getInstrument(ticker)}
        />
      </Col>
      <Col lg={5}>
        <Datetime
          dateFormat="YYYY-MM-DD"
          timeFormat={false}
          value={date}
          onChange={date => onChange({ ticker: ticker, date: date })}
          closeOnSelect
        />
      </Col>
    </Row>
  );
}

ComparisonInput.propTypes = {
  index: PropTypes.number.isRequired,
  date: PropTypes.any,
  ticker: PropTypes.string,
  instruments: PropTypes.array.isRequired,
  remove: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  instruments: state.market.instruments
});

export default connect(mapStateToProps, {})(ComparisonInput);
