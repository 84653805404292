import React from 'react'
import PropTypes from 'prop-types'

function Footer(props) {
    return (
        <footer className="footer">
        <div>
          <span>© 2020 Marketseasonals.com BETA. </span>
          {/* <span>Created by <a href="#">Kristian Arve</a></span> */}
        </div>
        {/* <div>
          <nav className="nav">
            <a href="https://themeforest.net/licenses/standard" className="nav-link">Licenses</a>
            <a href="../../change-log.html" className="nav-link">Change Log</a>
            <a href="https://discordapp.com/invite/RYqkVuw" className="nav-link">Get Help</a>
          </nav>
        </div> */}
      </footer>
    )
}

Footer.propTypes = {

}

export default Footer

