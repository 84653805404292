import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { getLastWeeks } from "../../../../../../../services/market";
import { dataObjectToDate, toDate } from "../../../../../../../utils/converter";
import Card from "../../../../../../layout/card/Card";
import CardBody from "../../../../../../layout/card/CardBody";
import LastWeeksChart from "./LastWeeksChart";

function LastWeeksCard({ ticker }) {
  const [loading, setLoading] = useState(true);
  const [charts, setCharts] = useState(null);

  // update chart data when ticker changes
  useEffect(() => {
    setLoading(true);
    getLastWeeks(ticker).then(data => {
      setCharts(data);
      setLoading(false);
    });
  }, [ticker]);

  return (
    <Card>
      <CardBody loading={loading}>
        <LastWeeksChart lastWeeks={charts} />
      </CardBody>
    </Card>
  );
}

LastWeeksCard.propTypes = {
  ticker: PropTypes.string.isRequired
};

export default LastWeeksCard;
