import React from "react";
import PropTypes from "prop-types";
import ReactEcharts from "echarts-for-react";
import {
  legendDefaults,
  xAxisDefaults,
  yAxisDefaults,
  seriesDefaults,
  chartWatermark
} from "../../../../../../charts/constants";
import { toDate } from "../../../../../../../utils/converter";


function WeeklyVolatilityChart({volatility}) {

  const options = {
    legend: legendDefaults,
    title: {
      text: "Weekly Volatility",
      subtext: "Average weekly volatility based on the range of the candles."
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        animation: false
      }
    },

    xAxis: {
      ...xAxisDefaults,
      type: "category",
      data: volatility.x.map(x => toDate(x, "ddd HH:mm"))
    },
    yAxis: [
      {
        ...yAxisDefaults,
        name: "Percent",
        type: "value",
        splitLine: {
          show: false
        }
      },
    ],
    graphic: [chartWatermark],
    series: {
        ...seriesDefaults,
        name: "Volatility",
        type: "line",
        showSymbol: false,
        data: volatility.y,
        areaStyle: {}
    }
  };

  return (
    <ReactEcharts
      option={options}
      style={{ height: "400px" }}
      theme={"theme"}
    />
  );
}

WeeklyVolatilityChart.propTypes = {};

export default WeeklyVolatilityChart;
