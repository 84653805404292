import React from 'react'
import PropTypes from 'prop-types'
import ReactEcharts from "echarts-for-react";
import moment from "moment";
import {   legendDefaults,
    xAxisDefaults,
    yAxisDefaults,
    seriesDefaults,
    chartWatermark } from '../../charts/constants'
import {   toDate,
    convertToCategorySeries } from '../../../utils/converter';

function ResultChart({result}) {

    const options = {
        legend: legendDefaults,
        title: {
          text: "Backtest Result",
          subtext: result.query
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            animation: false
          }
        },
    
        xAxis: {
          ...xAxisDefaults,
          type: "time",
        },
        yAxis: [
          {
            ...yAxisDefaults,
            name: "Percent",
            type: "value",
            splitLine: {
              show: false
            }
          }
        ],
        graphic: [chartWatermark],
        series: [
            {
                ...seriesDefaults,
                name: "Cumulative Performance",
                type: "line",
                showSymbol: false,
                data: convertToCategorySeries(result.x, result.y_return, value =>
                  toDate(value)
                )
              }
        ]
        // series: performances.map((data, ix) => ({
        //   ...seriesDefaults,
        //   name: data.name,
        //   type: "line",
        //   showSymbol: false,
        //   data: convertToCategorySeries(data.x, data.y, value =>
        //     toDate(value)
        //   )
        // }))
      };

    return (
        <ReactEcharts
          option={options}
          style={{ height: "400px" }}
          theme={"theme"}
        />
      );
}

ResultChart.propTypes = {

}

export default ResultChart

