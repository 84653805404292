import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getPatterns } from "../../../../../../../services/market";
import Card from "../../../../../../layout/card/Card";
import CardBody from "../../../../../../layout/card/CardBody";
import PatternsChart from "./PatternsChart";

function PatternsCard({ ticker }) {
  const [loading, setLoading] = useState(true);
  const [charts, setCharts] = useState(null);

  // update chart data when ticker changes
  useEffect(() => {
    setLoading(true);
    getPatterns(ticker, "week", 10).then(data => {
      setCharts(data);
      setLoading(false);
    });
  }, [ticker]);

  return (
    <Card>
      <CardBody loading={loading}>
        <PatternsChart lastWeeks={charts} />
      </CardBody>
    </Card>
  );
}

PatternsCard.propTypes = {
  ticker: PropTypes.string.isRequired
};

export default PatternsCard;
