import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import Backdrop from "../Backdrop";

function NavLink({ text, to, icon, children, onClick }) {
  const [subActive, setSubActive] = useState(false);

  let className = children ? " with-sub" : "";
  className = subActive ? className + " show" : className;

  const toggleSubNav = () => setSubActive(false);

  return (
    <>
      <li
        className={"nav-item" + className}
        onClick={onClick ? onClick : undefined}
      >
        {children ? (
          <a className="nav-link" onClick={() => setSubActive(true)}>
            {icon && icon}
            {text}
          </a>
        ) : to.startsWith("http") ? (
          <a href={to} className="nav-link">
            {icon && icon} {text}
          </a>
        ) : (
          <Link to={to} className="nav-link">
            {icon && icon}
            {text}
          </Link>
        )}
        {children && children(toggleSubNav)}
        {children && (
          <Backdrop
            hidden={true}
            active={subActive}
            toggle={() => setSubActive(false)}
          />
        )}
      </li>
    </>
  );
}

NavLink.propTypes = {
  text: PropTypes.string.isRequired,
  to: PropTypes.string,
  children: PropTypes.func,
  icon: PropTypes.node,
};

export default NavLink;
