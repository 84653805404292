import React from "react";
import PropTypes from "prop-types";
import { useApiCall } from "../../../../../../hooks/useApiCall";
import { getCharts } from "../../../../../../services/market";
import Card from "../../../../../layout/card/Card";
import CardBody from "../../../../../layout/card/CardBody";
import SessionsChart from "./SessionsChart";

function SessionsCard({ ticker }) {
  const chartTypes = ["us_session", "europe_session", "japan_session"];
  const loadData = () => getCharts(ticker, chartTypes);

  const [data, loading] = useApiCall(loadData, ticker, true, (res) => res);

  return (
    <Card>
      <CardBody loading={loading}>
        {data && <SessionsChart data={data} />}
      </CardBody>
    </Card>
  );
}

SessionsCard.propTypes = {
  ticker: PropTypes.string.isRequired,
};

export default SessionsCard;
