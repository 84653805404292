import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getNews } from "../../../services/market";

function NewsList({ ticker }) {
  const [news, setNews] = useState();

  useEffect(() => {
    {
      loadData(ticker);
    }
  }, [ticker]);

  const loadData = ticker => getNews(ticker).then(data => setNews(data));

  return (
    <>
      {news ? (
        <ul style={{ padding: "0 20px" }}>
          {news.map(x => (
            <li style={{ listStyle: "none" }}>
              {x.date}:{" "}
              <a
                href={x.link}
                target="_blank"
                style={{
                  width: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap"
                }}
              >
                {x.title}
              </a>
            </li>
          ))}
        </ul>
      ) : (
        <span>Loading...</span>
      )}
    </>
  );
}

NewsList.propTypes = {};

export default NewsList;
