import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import BasePage from "../../layout/page/BasePage";
import PageTitle from "../../layout/page/PageTitle";
import PerformanceCard from "./cards/PerformanceCard/PerformanceCard";
import { getInstrumentsByCategory } from "../../../services/market";
import { Row, Col } from "reactstrap";

function Forex(props) {
  const [instruments, setInstruments] = useState([]);

  useEffect(() => {
    getInstrumentsByCategory(4).then(data => setInstruments(data));
  }, []);

  return (
    <BasePage>
      <PageTitle title="Forex" />
      <Row>
        <Col lg={6}>
          <PerformanceCard 
          title="FX Majors Performance"
          category={4}
          />
        </Col>
      </Row>
      {/* <Row>
        <Col lg={6}>
          <PerformanceCard 
          title="FX Crosses Performance"
          category={4}
          />
        </Col>
      </Row> */}
    </BasePage>
  );
}

Forex.propTypes = {};

export default Forex;
