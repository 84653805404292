import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { FormGroup, Input, Label, Button, Col, Row } from "reactstrap";

import MarketHeader from "../market/MarketHeader";
import Sidebar from "./Sidebar";
import CriteriaSelector from "./CriteriaSelector";
import Card from "../../layout/card/Card";
import CardBody from "../../layout/card/CardBody";
import axios from "axios";
import ResultChart from "./ResultChart";
import MarketSelector from "../../ui/MarketSelector";

function Backtester(props) {
  const [code, setCode] = useState("");
  const [market, setMarket] = useState(null);
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);

  // const ticker = "EURUSD";

  const onSubmit = () => {
    setResult(null);
    setLoading(true);

    const params = {
      ticker: market.ticker,
      code: code,
    };

    axios.post("/api/backtest", params).then((res) => {
      setLoading(false);
      setResult(res.data);
    });
  };

  return (
    <>
      <div className="filemgr-wrapper">
        <Sidebar />
        <div className="filemgr-content">
          <div className="filemgr-content-header">
            <h2 className="mg-0" style={{ fontWeight: 300, fontSize: "1.5em" }}>
              Backtester
            </h2>
          </div>

          <div className="filemgr-content-body" id="main-content">
            <div className="pd-20 pd-lg-25 pd-xl-30">
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody loading={!Boolean(props.instruments)}>
                      <Row>
                        <Col lg={4}>
                          <FormGroup>
                            <MarketSelector
                              instruments={props.instruments}
                              onChange={(x) => setMarket(x)}
                              value={market}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={2}>
                          <FormGroup>
                            <Label>
                              <Input
                                type="text"
                                name="numBars"
                                // onChange={e =>
                                //   this.setState({ numBars: e.target.value })
                                // }
                                // value={this.state.numBars}
                                placeholder="Number of Bars"
                              />
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col lg={3}>
                          <FormGroup check>
                            <Label check style={{ marginRight: "35px" }}>
                              <Input
                                type="radio"
                                name="direction"
                                // onChange={this.handleChange}
                              />{" "}
                              Long
                            </Label>
                            <Label check>
                              <Input
                                type="radio"
                                name="direction"
                                // onChange={this.handleChange}
                              />{" "}
                              Short
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Card>
                <CardBody>
                  <FormGroup>
                    <Label for="exampleText">Code</Label>
                    <Input
                      type="textarea"
                      name="text"
                      onChange={(e) => setCode(e.target.value)}
                      rows={10}
                    />
                  </FormGroup>
                  <Button onClick={onSubmit}>Run Backtest</Button>
                </CardBody>
              </Card>

              {(result || loading) && (
                <Card>
                  <CardBody loading={loading}>
                    <ResultChart result={result} />
                  </CardBody>
                </Card>
              )}

              {/* <CriteriaSelector /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

Backtester.propTypes = {};

const mapStateToProps = (state) => ({
  instruments: state.market.instruments,
});

export default connect(mapStateToProps, {})(Backtester);
