import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";

import { loginUser, setUser } from "../../../redux/ducks/account/actions";
import { connect } from "react-redux";
import Loading from "../../layout/Loading";

function Login({ loginUser, setUser, account }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  let history = useHistory();
  let location = useLocation();

  const login = (email, password) => {
    setLoading(true);
    axios
      .post("/api/account/login/", { email: email, password: password })
      .then((res) => {
        setLoading(false);
        setUser(res.data.email, res.data.username);
      })
      .catch((e) => {
        setLoading(false);
        setError(e.response.data.detail);
      });
  };

  useEffect(() => {
    if (account.loggedIn) {
      if (location.state) {
        // return <Redirect to={location.state.from.pathname} />
        history.replace(location.state.from);
      } else {
        // return <Redirect to="/" />
        history.replace("/");
      }
    }
  }, [account.loggedIn]);

  return (
    <div className="content content-fixed content-auth">
      <div className="container">
        <div className="media align-items-stretch justify-content-center ht-100p pos-relative">
          <div className="sign-wrapper mg-lg-l-50 mg-xl-l-60">
            <div className="wd-100p">
              <h3 className="tx-color-01 mg-b-5">Sign In</h3>
              <p className="tx-color-03 tx-16 mg-b-40">
                Welcome! Please sign in to continue.
              </p>
              {loading ? (
                <Loading />
              ) : (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    // login(email, password);
                  }}
                  method="post"
                >
                  <div className="form-group">
                    <label>Email address</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="yourname@yourmail.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <div className="d-flex justify-content-between mg-b-5">
                      <label className="mg-b-0-f">Password</label>
                      {/* <a href className="tx-13">
                        Forgot password?
                      </a> */}
                    </div>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter your password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  {error && (
                    <div className="form-group">
                      <div className="invalid-feedback">
                        {error}
                        </div>
                    </div>
                  )}
                  <button
                    className="btn btn-brand-02 btn-block"
                    onClick={() => login(email, password)}
                  >
                    Sign In
                  </button>

                  {/* <div className="tx-13 mg-t-20 tx-center">
                    Don't have an account?{" "}
                    <Link to="/register">Create an Account</Link>
                  </div> */}

                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  account: state.account,
});

export default connect(mapStateToProps, { loginUser, setUser })(Login);
