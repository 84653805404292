import React, { useState } from "react";
import PropTypes from "prop-types";

function WithModal({ children }) {
  const [modalActive, setModalActive] = useState(false);
  const toggleModal = () => setModalActive(!modalActive);

  return children(modalActive, toggleModal);
}

WithModal.propTypes = {};

export default WithModal;
