import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import Sidebar from "./Sidebar";
import Weeks from "./subpages/weeks/Weeks";
import Overview from "./subpages/overview/Overview";
import InteractiveChart from "./subpages/interactive-chart/InteractiveChart";
import Years from "./subpages/years/Years";
import Patterns from "./subpages/patterns/Patterns";
import MarketHeader from "./MarketHeader";
import Months from "./subpages/months/Months";
import Weekdays from "./subpages/weekdays/Weekdays";
import Sentiment from "./subpages/sentiment/Sentiment";
import CompareData from "./subpages/compare-data/CompareData";
import CustomStudies from "./subpages/custom-studies/CustomStudies";
import CustomSeasonality from "./subpages/custom-seasonality/CustomSeasonality";
import PrivateRoute from "../../wrapper/PrivateRoute";
import TimeOfDay from "./subpages/time-of-day/TimeOfDay";

function Market(props) {
  const ticker = props.match.params.ticker;
  const instruments = props.instruments || [];
  const activeInstrument = instruments.find(
    (x) => x.ticker.toLowerCase() === ticker.toLowerCase()
  );

  useEffect(() => {
    document.body.classList.add("app-filemgr");
    return () => {
      document.body.classList.remove("app-filemgr");
    };
  }, []);

  useEffect(() => {
    document.body.classList.remove("navbar-nav-show");
  }, [ticker]);

  return (
    <>
      <div className="filemgr-wrapper">
        <Sidebar ticker={ticker} activeInstrument={activeInstrument} />
        <div className="filemgr-content">
          <MarketHeader ticker={ticker} instrument={activeInstrument} />
          <div className="filemgr-content-body" id="main-content">
            <div className="pd-20 pd-lg-25 pd-xl-30">
              <Switch>
                <PrivateRoute
                  path={`/market/${ticker}/weeks`}
                  exact
                  render={(props) => <Weeks ticker={ticker} {...props} />}
                />
                <PrivateRoute
                  path={`/market/${ticker}/years`}
                  exact
                  render={(props) => <Years ticker={ticker} {...props} />}
                />
                <PrivateRoute
                  path={`/market/${ticker}/months`}
                  exact
                  render={(props) => <Months ticker={ticker} {...props} />}
                />
                <PrivateRoute
                  path={`/market/${ticker}/weekdays`}
                  exact
                  render={(props) => <Weekdays ticker={ticker} {...props} />}
                />
                <PrivateRoute
                  path={`/market/${ticker}/time-of-day`}
                  exact
                  render={(props) => <TimeOfDay ticker={ticker} {...props} />}
                />
                <PrivateRoute
                  path={`/market/${ticker}/patterns`}
                  exact
                  render={(props) => <Patterns ticker={ticker} {...props} />}
                />
                <PrivateRoute
                  path={`/market/${ticker}/sentiment`}
                  exact
                  render={(props) => <Sentiment ticker={ticker} {...props} />}
                />
                <PrivateRoute
                  path={`/market/${ticker}/compare`}
                  exact
                  render={(props) => <CompareData ticker={ticker} {...props} />}
                />
                <PrivateRoute
                  path={`/market/${ticker}/chart`}
                  exact
                  render={(props) => (
                    <InteractiveChart ticker={ticker} {...props} />
                  )}
                />
                <PrivateRoute
                  path={`/market/${ticker}/custom-seasonality`}
                  exact
                  render={(props) => (
                    <CustomSeasonality ticker={ticker} {...props} />
                  )}
                />
                <PrivateRoute
                  path={`/market/${ticker}/custom-studies`}
                  exact
                  render={(props) => (
                    <CustomStudies ticker={ticker} {...props} />
                  )}
                />
                <PrivateRoute
                  path=""
                  render={(props) => <Overview ticker={ticker} {...props} />}
                />
              </Switch>
            </div>
          </div>
        </div>
        {/* filemgr-content */}
      </div>
      {/* filemgr-wrapper */}
    </>
  );
}

Market.propTypes = {};

const mapStateToProps = (state) => ({
  instruments: state.market.instruments,
});

export default connect(mapStateToProps, {})(Market);
