import React from "react";
import PropTypes from "prop-types";
import ReactEcharts from "echarts-for-react";
import {
  legendDefaults,
  xAxisDefaults,
  yAxisDefaults,
  seriesDefaults,
  chartWatermark
} from "../../../../../../charts/constants";

function SeasonalityChart({ currentData, avgData, chartDescription }) {
  const options = {
    legend: legendDefaults,
    title: {
      text: chartDescription.title,
      subtext: chartDescription.description
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        animation: false
      }
    },

    xAxis: {
      ...xAxisDefaults,
      type: "category",
      data: avgData.x
    },
    yAxis: [
      {
        ...yAxisDefaults,
        name: "Price",
        type: "value",
        splitLine: {
          show: false
        }
      },
      {
        ...yAxisDefaults,
        type: "value",
        splitLine: {
          show: false
        }
      }
    ],
    graphic: [chartWatermark],
    series: [
      {
        ...seriesDefaults,
        name: "NAME",
        type: "line",
        yAxisIndex: 1,
        showSymbol: false,
        data: avgData.y.map((y, ix) => {
          return { value: [avgData.x[ix], y] };
        })
      },
      {
        ...seriesDefaults,
        name: "TICKER",
        type: "line",
        showSymbol: false,
        data: currentData.y.map((y, ix) => {
          return { value: [currentData.x[ix], y] };
        })
      }
    ]
  };

  return (
    <ReactEcharts
      option={options}
      style={{ height: "400px" }}
      theme={"theme"}
    />
  );
}

SeasonalityChart.propTypes = {};

export default SeasonalityChart;
