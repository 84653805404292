import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { Input } from "reactstrap";
import axios from "axios";

function ThankYou(props) {
  console.log("encoded email: ", props.match.params.email);
  const email = atob(props.match.params.email);
  console.log("email: ", email);

  return (
    <div className="content content-fixed content-auth">
      <div className="container">
        <div className="media align-items-stretch justify-content-center ht-100p">
          <div className="sign-wrapper mg-lg-r-50 mg-xl-r-60">
            <div className="pd-t-20 wd-100p" style={{ textAlign: "center" }}>
              <div style={{ width: "35%", margin: "40px auto" }}>
                <svg viewBox="0 0 98.5 98.5">
                  <path
                    className="svg-checkmark"
                    fill="none"
                    d="M81.7 17.8C73.5 9.3 62 4 49.2 4 24.3 4 4 24.3 4 49.2s20.3 45.2 45.2 45.2 45.2-20.3 45.2-45.2c0-8.6-2.4-16.6-6.5-23.4L45.6 68.2 24.7 47.3"
                  />
                </svg>
              </div>
              <h4 className="tx-color-01 mg-b-5">Thank you for signing up!</h4>
              <p className="tx-color-03 tx-16 mg-b-40">
                You should receive an email shortly ({email}) with a link to
                activate your account.
              </p>
            </div>
          </div>
          {/* sign-wrapper */}
        </div>
        {/* media */}
      </div>
      {/* container */}
    </div>
  );
}

ThankYou.propTypes = {};

export default ThankYou;
