import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useMatomo } from "@datapunt/matomo-tracker-react";

import Header from "./components/layout/header/Header";
import Backdrop from "./components/layout/Backdrop";
import Market from "./components/pages/market/Market";
import Dashboard from "./components/pages/dashboard/Dashboard";
import Landing from "./components/pages/landing/Landing";
import ErrorBoundary from "./components/wrapper/ErrorBoundary";
import MarketOverview from "./components/pages/market/MarketOverview";
import NewsEvents from "./components/pages/news-events/NewsEvents";
import NewsEventsDetail from "./components/pages/news-events/NewsEventsDetail";
import { getActiveInstruments } from "./redux/ducks/market/actions";
import Backtester from "./components/pages/backtester/Backtester";
import Forex from "./components/pages/category/Forex";
import Indices from "./components/pages/category/Indices";
import Login from "./components/pages/account/Login";
import Register from "./components/pages/account/Register";
import ThankYou from "./components/pages/account/ThankYou";
import VerifyAccount from "./components/pages/account/VerifyAccount";
import PrivateRoute from "./components/wrapper/PrivateRoute";
import { checkLogin } from "./redux/ducks/account/actions";
import Account from "./components/pages/account/Account";
import Error404 from "./components/pages/Error404";
import PublicRouteOnly from "./components/wrapper/PublicRouteOnly";

function App({ getActiveInstruments, checkLogin, username }) {
  // console.log("END: ", process.env)
  // check if process.env.NODE_ENV !== "development"

  useEffect(() => {
    // preload stuff here
    checkLogin();
    getActiveInstruments();
  }, []);

  const location = useLocation();

  const { trackPageView, trackEvent } = useMatomo();

  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      trackPageView({
        href: location.pathname + location.search || "", // optional
        customDimensions: [
          {
            id: 1,
            value: username,
          },
        ], // optional
      });
    }
  }, [location]);

  return (
    <>
      <Helmet htmlAttributes>
        <title>Marketseasonals.com</title>
        <meta
          name="description"
          content="Marketseasonals.com - Professional Market Research Tool"
        />
      </Helmet>
      <Header />
      <ErrorBoundary>
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route exact path="/login" component={Login} />
          <PublicRouteOnly exact path="/register" component={Register} />
          <PrivateRoute exact path="/account/" component={Account} />
          <Route exact path="/account/verify" component={VerifyAccount} />
          <Route exact path="/thank-you/:email" component={ThankYou} />
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/market/forex" component={Forex} />
          <Route exact path="/market/indices" component={Indices} />
          <Route path="/market/:ticker" component={Market} />
          <Route exact path="/market" component={MarketOverview} />
          <Route exact path="/backtester" component={Backtester} />
          <Route exact path="/news-events" component={NewsEvents} />
          <Route exact path="/news-events/:id" component={NewsEventsDetail} />
          <Route component={Error404} />
        </Switch>
      </ErrorBoundary>
    </>
  );
}

const mapStateToProps = (state) => ({
  username: state.account.username,
});

export default connect(mapStateToProps, { getActiveInstruments, checkLogin })(
  App
);
