import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import promise from 'redux-promise-middleware'

import * as reducers from './ducks';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState = {}) {
    const rootReducer = combineReducers(reducers);
    return createStore(
      rootReducer,
      initialState,
      composeEnhancer(applyMiddleware(promise))
    );
  }

