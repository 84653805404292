import React from "react";
import PropTypes from "prop-types";
import ReactEcharts from "echarts-for-react";
import moment from "moment";
import {
  chartWatermark,
  legendDefaults,
  xAxisDefaults,
  yAxisDefaults,
  seriesDefaults
} from "../../../../../charts/constants";
import {
  toDate,
  convertToCategorySeries
} from "../../../../../../utils/converter";

function SentimentChart({ data }) {
  const options = {
    title: {
      text: "Sentiment",
      subtext: "Sibtext"
    },
    legend: { ...legendDefaults, show: false },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        animation: false
      }
    },
    axisPointer: {
      link: { xAxisIndex: "all" },
      label: {
        backgroundColor: "#777"
      }
    },
    xAxis: [
      {
        ...xAxisDefaults,
        type: "time",
        boundaryGap: false,
        gridIndex: 0
      },
      {
        ...xAxisDefaults,
        type: "time",
        gridIndex: 1,
        boundaryGap: false
      }
    ],
    visualMap: {
      top: 10,
      right: 10,
      seriesIndex: 1,
      // type: "continous",
      // type: 'continuous',
      show: false,
      pieces: [
        {
          gt: 0,
          lte: 50,
          color: "#096"
        },
        {
          gt: 50,
          lte: 100,
          color: "#ffde33"
        }
      ],
      outOfRange: {
        color: "#999"
      }
    },
    yAxis: [
      {
        ...yAxisDefaults,
        type: "value",
        min: "dataMin",
        max: "dataMax"
      },
      {
        ...yAxisDefaults,
        type: "value",
        gridIndex: 1,
        min: "dataMin",
        max: "dataMax"
      }
    ],
    grid: [
      {
        // left: "10%",
        // right: "8%",
        height: "40%"
      },
      {
        // left: "10%",
        // right: "8%",
        top: "70%",
        height: "20%"
      }
    ],
    graphic: [chartWatermark],
    series: [
      {
        ...seriesDefaults,
        name: "Price",
        scale: true,
        type: "line",
        showSymbol: false,
        // data: data.price_data.y
        data: convertToCategorySeries(
          data.price_data.x,
          data.price_data.y,
          value => toDate(value / 1000 / 1000)
        )
      },
      {
        ...seriesDefaults,
        name: "Sentiment",
        scale: true,
        type: "line",
        // stack: "总量k",
        areaStyle: {},
        showSymbol: false,
        data: convertToCategorySeries(
          data.sentiment_data.x,
          data.sentiment_data.y,
          value => toDate(value / 1000 / 1000)
        ),
        xAxisIndex: 1,
        yAxisIndex: 1
      }
    ]
  };


  return (
    <ReactEcharts
      option={options}
      style={{ height: "500px" }}
      theme={"theme"}
    />
  );
}

SentimentChart.propTypes = {
  data: PropTypes.object.isRequired
};

export default SentimentChart;
