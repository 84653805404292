import React from "react";
import PropTypes from "prop-types";
import ReactEcharts from "echarts-for-react";
import {
  legendDefaults,
  xAxisDefaults,
  yAxisDefaults,
  seriesDefaults,
  chartWatermark
} from "../../../../../../charts/constants";
import { toDate } from "../../../../../../../utils/converter";


function LastYearsChart({lastYears}) {

  const options = {
    legend: legendDefaults,
    title: {
      text: "Last Years",
      subtext: "Last 5 Years Price Movement"
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        animation: false
      }
    },

    xAxis: {
      ...xAxisDefaults,
      type: "category",
      data: lastYears.index.map(x => toDate(x, "MM-DD"))
    },
    yAxis: [
      {
        ...yAxisDefaults,
        name: "Percent",
        type: "value",
        splitLine: {
          show: false
        }
      },
    ],
    graphic: [chartWatermark],
    series: lastYears.data.map((data, ix) => ({
        ...seriesDefaults,
        name: lastYears.keys[ix],
        type: "line",
        showSymbol: false,
        data: data,
        lineStyle: {
          width: ix === lastYears.data.length - 1 ? 3 : 2,
          type: ix === lastYears.data.length - 1 ? "solid" : "dotted"
        },
    }))
  };

  return (
    <ReactEcharts
      option={options}
      style={{ height: "400px" }}
      theme={"theme"}
    />
  );
}

LastYearsChart.propTypes = {};

export default LastYearsChart;
