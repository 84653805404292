import React from "react";
import PropTypes from "prop-types";
import { useApiCall } from "../../../../../../hooks/useApiCall";
import { getSentimentData } from "../../../../../../services/market";
import SentimentChart from "./SentimentChart";
import Card from "../../../../../layout/card/Card";
import CardBody from "../../../../../layout/card/CardBody";

function SentimentCard({ ticker }) {
  const loadData = () =>
    getSentimentData({
      ticker: ticker,
    //   start_datetime: new Date()
    });

  const [data, loading] = useApiCall(loadData, ticker, true, res => res);
  console.log("data: ", data)

  return (
    <Card>
      <CardBody loading={loading}>
        {data && <SentimentChart data={data} />}
      </CardBody>
    </Card>
  );
}

SentimentCard.propTypes = {
  ticker: PropTypes.string.isRequired
};

export default SentimentCard;
