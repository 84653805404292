import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

function Card(props) {
  return (
    <div
      className={classNames("card", props.className)}
      style={{ marginBottom: "10px" }}
    >
      {props.children}
    </div>
  );
}

Card.propTypes = {};

export default Card;
