import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Button, Row, Col } from "reactstrap";
import { useList } from "react-use";
import { PlusCircle as PlusIcon } from "react-feather";

import Card from "../../../../../layout/card/Card";
import CardBody from "../../../../../layout/card/CardBody";
import { useApiCall } from "../../../../../../hooks/useApiCall";
import { getComparisons } from "../../../../../../services/market";
import ComparisonInput from "./ComparisonInput";
import ComparisonChart from "./ComparisonChart";

function ComparisonCard({ ticker }) {
  const loadData = () => {
    const params = selectedDates.map(x => {
      return {
        week: x.date.isoWeek(),
        year: x.date.isoWeekYear(),
        ticker: x.ticker
      };
    });
    return getComparisons(ticker, { dates: params });
  };

  const [selectedDates, { updateAt, push, removeAt }] = useList([
    {
      ticker: ticker,
      date: moment()
    },
    {
      ticker: ticker,
      date: null
    }
  ]);

  const [comparisons, loading, load] = useApiCall(
    loadData,
    ticker,
    false,
    res => res
  );

  const validate = data => {
    for (var entry of data) {
      if (!entry.ticker || !entry.date) {
        return false;
      }
    }
    return true;
  };

  return (
    <>
      <Card>
        <CardBody>
          Compare Weeks:
          <hr />
          {selectedDates.map((x, ix) => (
            <ComparisonInput
              index={ix}
              ticker={x.ticker}
              date={x.date}
              onChange={e => updateAt(ix, e)}
              remove={() => removeAt(ix)}
            />
          ))}
          {selectedDates.length < 15 && (
            <Row>
              <Col lg={1} style={{textAlign: "center"}}>
                <PlusIcon
                  onClick={() =>
                    push({
                      ticker: ticker,
                      date: null
                    })
                  }
                  style={{ marginTop: "7px", cursor: "pointer" }}
                />
              </Col>
            </Row>
          )}
          <Button
            color="primary"
            size="sm"
            style={{ float: "right" }}
            disabled={!validate(selectedDates)}
            onClick={load}
          >
            Load Comparison
          </Button>
        </CardBody>
      </Card>
      {(comparisons || loading) && (
        <Card>
          <CardBody loading={loading}>
            {comparisons && <ComparisonChart compareWeeks={comparisons} />}
          </CardBody>
        </Card>
      )}
    </>
  );
}

ComparisonCard.propTypes = {
  ticker: PropTypes.string.isRequired
};

export default ComparisonCard;
