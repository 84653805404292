import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Search as SearchIcon, Menu as MenuIcon } from "react-feather";

import SearchBar from "./searchbar/SearchBar";
import Logo from "../Logo";
import RightNav from "./RightNav";
import Nav from "./nav/Nav";
import Backdrop from "../Backdrop";
import { toggleMarketNav } from "../../../redux/ducks/layout/actions";

function Header(props) {
  const marketNavActive = useSelector((state) => state.layout.marketNavActive);
  const dispatch = useDispatch();

  const isWideEnough = useMediaQuery({
    query: "(min-width: 992px)",
  });

  const onMenuClick = (e) => {
    if (document.body.classList.contains("app-filemgr")) {
      // document.body.classList.toggle("filemgr-sidebar-show");
      dispatch(toggleMarketNav());
      e.stopPropagation()
    } else {
      document.body.classList.toggle("navbar-nav-show");
    }
  };

  return (
    <>
      <header className="navbar navbar-header navbar-header-fixed">
        <a id="mainMenuOpen" className="burger-menu" onClick={onMenuClick}>
          <MenuIcon />
        </a>
        <div className="navbar-brand" style={{ marginTop: "0px" }}>
          <Logo />
        </div>
        <Nav />
        <RightNav />
        <SearchBar />
        <Backdrop
          // active={document.body.classList.contains("filemgr-sidebar-show")}
          toggle={() => {
            document.body.classList.remove("navbar-nav-show");
          }}
        />
      </header>
    </>
  );
}

Header.propTypes = {};

export default Header;
