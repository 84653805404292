import React from "react";
import PropTypes from "prop-types";
import { NavLink, Link } from "react-router-dom";

function Sidebar({ ticker }) {
  return (
    <div className="filemgr-sidebar">
      <div className="filemgr-sidebar-header">
        <div className="dropdown dropdown-icon flex-fill">
          <button
            className="btn btn-xs btn-block btn-white"
            data-toggle="dropdown"
          >
            New <i data-feather="chevron-down" />
          </button>
          <div className="dropdown-menu tx-13">
            <a href="#" className="dropdown-item">
              <i data-feather="folder" />
              <span>Folder</span>
            </a>
            <a href="#" className="dropdown-item">
              <i data-feather="file" />
              <span>Notes</span>
            </a>
            <div className="dropdown-divider" />
            <a href="#" className="dropdown-item">
              <i data-feather="file-text" />
              <span>Word Document</span>
            </a>
            <a href="#" className="dropdown-item">
              <i data-feather="file-text" />
              <span>Powepoint</span>
            </a>
            <a href="#" className="dropdown-item">
              <i data-feather="file-text" />
              <span>Excel Spreadsheet</span>
            </a>
          </div>
          {/* dropdown-menu */}
        </div>
        {/* dropdown */}
        <div className="dropdown dropdown-icon flex-fill mg-l-10">
          <button
            className="btn btn-xs btn-block btn-primary"
            data-toggle="dropdown"
          >
            + Save <i data-feather="chevron-down" />
          </button>
          <div className="dropdown-menu tx-13">
            <a href="#" className="dropdown-item">
              <i data-feather="file" />
              <span>File</span>
            </a>
            <a href="#" className="dropdown-item">
              <i data-feather="folder" />
              <span>Folder</span>
            </a>
          </div>
          {/* dropdown-menu */}
        </div>
        {/* dropdown */}
      </div>
      {/* filemgr-sidebar-header */}
      <div className="filemgr-sidebar-body">
        <div className="pd-t-20 pd-b-10 pd-x-10">
          <label className="tx-sans tx-uppercase tx-medium tx-10 tx-spacing-1 tx-color-03 pd-l-10">
            Rules
          </label>
          <nav className="nav nav-sidebar tx-13">
            <NavLink to={`/market/${ticker}/`} className="nav-link" exact>
              <span>Overview</span>
            </NavLink>
            <NavLink to={`/market/${ticker}/years`} className="nav-link">
              <span>Years</span>
            </NavLink>
            <NavLink to={`/market/${ticker}/months`} className="nav-link">
              <span>Months</span>
            </NavLink>
            <NavLink to={`/market/${ticker}/weeks`} className="nav-link">
              <span>Weeks</span>
            </NavLink>
            <NavLink to={`/market/${ticker}/weekdays`} className="nav-link">
              <span>Weekdays</span>
            </NavLink>
          </nav>
        </div>
        <div className="pd-10">
          <label className="tx-sans tx-uppercase tx-medium tx-10 tx-spacing-1 tx-color-03 pd-l-10">
            Tools
          </label>
          <nav className="nav nav-sidebar tx-13">
            <NavLink to={`/market/${ticker}/patterns`} className="nav-link">
              <span>Pattern Matches</span>
            </NavLink>
            <NavLink to={`/market/${ticker}/chart`} className="nav-link">
              <span>Interactive Chart</span>
            </NavLink>
            <a href="#" className="nav-link">
              <i data-feather="image" /> <span>Custom Studies</span>
            </a>
            {/* <a href="#"className="nav-link"><i data-feather="video" /> <span>Videos</span></a>
                <a href="#"className="nav-link"><i data-feather="music" /> <span>Audio</span></a>
                <a href="#"className="nav-link"><i data-feather="package" /> <span>Zip Files</span></a> */}
          </nav>
        </div>
        {/* 
        <div className="pd-y-10 pd-x-20">
          <label className="tx-sans tx-uppercase tx-medium tx-10 tx-spacing-1 tx-color-03 mg-b-15">
            Storage Status
          </label>
          <div className="media">
            <i data-feather="database" className="wd-30 ht-30" />
            <div className="media-body mg-l-10">
              <div className="tx-12 mg-b-4">10.2GB used of 50GB</div>
              <div className="progress ht-3 mg-b-20">
                <div
                  className="progress-bar wd-15p"
                  role="progressbar"
                  aria-valuenow={15}
                  aria-valuemin={0}
                  aria-valuemax={100}
                />
              </div>
            </div>
          </div>
          <p className="tx-12">
            Get 2TB (2,000GB) of storage now and get 40% off. Offers ends soon.{" "}
            <a href="#">Learn more</a>
          </p>
        </div>
       */}
      </div>
      {/* filemgr-sidebar-body */}
    </div>
  );
}

Sidebar.propTypes = {};

export default Sidebar;
