import React from "react";
import PropTypes from "prop-types";
import ReactEcharts from "echarts-for-react";
import moment from "moment"
import {
  legendDefaults,
  xAxisDefaults,
  yAxisDefaults,
  seriesDefaults,
  chartWatermark
} from "../../../../../../charts/constants";
import { toDate } from "../../../../../../../utils/converter";

function WeekdayAveragesChart({ averages }) {
  const options = {
    legend: legendDefaults,
    title: {
      text: "Weekday Averages",
      subtext: "Average Weekday Performance"
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        animation: false
      }
    },

    xAxis: {
      ...xAxisDefaults,
      type: "category",
      data: averages[0].x
    },
    yAxis: [
      {
        ...yAxisDefaults,
        name: "Percent",
        type: "value",
        splitLine: {
          show: false
        }
      }
    ],
    graphic: [chartWatermark],
    series: averages.map((data, ix) => ({
      ...seriesDefaults,
      name: data.name,
      type: "line",
      showSymbol: false,
      data: data.y
    }))
  };

  return (
    <ReactEcharts
      option={options}
      style={{ height: "400px" }}
      theme={"theme"}
    />
  );
}

WeekdayAveragesChart.propTypes = {};

export default WeekdayAveragesChart;
