import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import YearlyVolatilityCard from "./cards/YearlyVolatilityCard/YearlyVolatilityCard";
import LastYearsCard from "./cards/LastYearsCard/LastYearsCard";
import CurrentYearCard from "./cards/CurrentYearCard/CurrentYearCard";

function Years({ ticker }) {
  return (
    <>
      <Row>
        <Col lg={6}>
          <CurrentYearCard ticker={ticker} />
        </Col>
        <Col lg={6}>
          <YearlyVolatilityCard ticker={ticker} />
        </Col>
        <Col lg={6}>
          <LastYearsCard ticker={ticker} />
        </Col>
      </Row>
    </>
  );
}

Years.propTypes = {
  ticker: PropTypes.string.isRequired
};

export default Years;
