import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import PatternsCard from "./cards/PatternsCard/PatternsCard";
import PatternContextCard from "./cards/PatternContext/PatternContextCard";

function Patterns({ ticker }) {
  return (
    <>
      <Row>
        <Col lg={12}>
          <PatternsCard ticker={ticker} />
        </Col>
        <Col>
          <PatternContextCard ticker={ticker} />
        </Col>
      </Row>
    </>
  );
}

Patterns.propTypes = {};

export default Patterns;
