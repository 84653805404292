import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";

import LastWeeksCard from "./cards/LastWeeksCard/LastWeeksCard";
import WeeklyVolatilityCard from "./cards/WeeklyVolatilityCard/WeeklyVolatilityCard";
import CurrentWeekCard from "./cards/CurrentWeekCard/CurrentWeekCard";
import WeeklyHighLowCard from "./cards/WeeklyHighLowsCard/WeeklyHighLowCard";

function Weeks({ ticker }) {
  return (
    <>
      <Row>
        <Col md={12} lg={6}>
          <CurrentWeekCard ticker={ticker} />{" "}
        </Col>
        <Col md={12} lg={6}>
          <LastWeeksCard ticker={ticker} />
        </Col>
        <Col md={12} lg={6}>
          <WeeklyHighLowCard ticker={ticker} />
        </Col>
        <Col md={12} lg={6}>
          <WeeklyVolatilityCard ticker={ticker} />
        </Col>
      </Row>
    </>
  );
}

Weeks.propTypes = {
  ticker: PropTypes.string
};

export default Weeks;
