const theme = {

// color: [
//   "#516b91",
//   "#59c4e6",
//   "#edafda",
//   "#93b7e3",
//   "#a5e7f0",
//   "#cbb0e3"
// ],
color: ["#c23531",
"#2f4554",
"#61a0a8",
"#d48265",
"#91c7ae",
"#749f83",
"#ca8622",
"#bda29a",
"#6e7074",
"#546570",
"#c4ccd3"],
textStyle: {
  fontFamily: '"IBM Plex Sans", sans-serif',
},
  title: {
    // textAlign: "center",
    // left: "50%",
    // padding: [5, 0, 150, 5],
    // padding: 5,
    // itemGap: 50,
    textStyle: {
      fontFamily: '"IBM Plex Sans", sans-serif',
      fontWeight: "500",
      fontSize: 14,
      color: "#001737"
    },
  },
  legend: {
      textStyle: {
        fontFamily: '"IBM Plex Sans", sans-serif',
        // fontWeight: "bold",
        // fontSize: 14,
        fontSize: "10%"
      }
  },
  yAxis: {
    textStyle: {
      fontFamily: '"IBM Plex Sans", sans-serif',
      // fontWeight: "bold",
      fontSize: 14
    }
},
  grid: {
    left: 50,
    top: 80,
    right: 50,
    bottom: 70,
    backgroundColor: 'rgba(0,0,0,0)',
  },
//   candlestick: {
//     itemStyle: {
//             color: "#5DB278",
//             color0: "#af5d5d",
//             borderColor: "#5DB278",
//             borderColor0: "#af5d5d",
//             borderWidth: 1
        
//     }
// },
  backgroundColor: '#fff'
};

export default theme;
