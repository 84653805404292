import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Card from "../../../../../../layout/card/Card";
import CardBody from "../../../../../../layout/card/CardBody";
import { getCharts } from "../../../../../../../services/market";
import MonthlyPerformanceChart from "./MonthlyPerformanceChart";

function MonthlyPerformanceCard({ ticker }) {
  const [loading, setLoading] = useState(true);
  const [charts, setCharts] = useState(null);

  // todo: use range here
  const monthNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const chartTypes = monthNumbers.map(x => `month_perf_${x}`);

  // update chart data when ticker changes
  useEffect(() => {
    setLoading(true);
    getCharts(ticker, chartTypes).then(data => {
      setCharts(data);
      setLoading(false);
    });
  }, [ticker]);

  return (
    <Card>
      <CardBody loading={loading}>
        <MonthlyPerformanceChart performances={charts} />
      </CardBody>
    </Card>
  );
}

MonthlyPerformanceCard.propTypes = {};

export default MonthlyPerformanceCard;
