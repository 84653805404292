import * as types from "./types";
import * as services from "./services";

export const loginUser = (username, password) => ({
  type: types.LOGIN_USER,
  payload: services.loginUser(username, password)
});

export const logoutUser = () => ({
  type: types.LOGOUT_USER,
  payload: services.logoutUser()
});

export const checkLogin = () => ({
  type: types.CHECK_LOGIN,
  payload: services.checkLogin()
})

export const setUser = (email, username) => ({
  type: types.SET_USER,
  payload: {email: email, username: username}
})