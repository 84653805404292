import React from "react";
import PropTypes from "prop-types";
import { Col } from "reactstrap";
import { useMediaQuery } from "react-responsive";

import PerformanceLabel from "../../ui/PerformanceLabel";
import MarketSearchMini from "../../ui/MarketSearchMini";

function MarketHeader({ ticker, instrument }) {
  const isWideEnough = useMediaQuery({
    query: "(min-width: 992px)",
  });

  // todo: change this to show react-loader thing
  if (!instrument) return null;

  return (
    <div className="filemgr-content-header">
      <Col lg={8} md={12}>
        <h2 className="mg-0" style={{ fontWeight: 300, fontSize: "1.5em" }}>
          {instrument.name}
          {isWideEnough && (
            <PerformanceLabel value={instrument.wtd_pct} text={"week"} />
          )}
          <PerformanceLabel value={instrument.mtd_pct} text={"month"} />
          <PerformanceLabel value={instrument.ytd_pct} text={"year"} />
        </h2>
      </Col>
      {/* <Col lg={4} xs={12} className="md-hidden">
        <i data-feather="search" />
        <div className="search-form">
          <MarketSearchMini />
        </div>
      </Col> */}
    </div>
  );
}

MarketHeader.propTypes = {
  ticker: PropTypes.string.isRequired,
  instrument: PropTypes.object,
};

export default MarketHeader;
