import * as types from "./types";

const initialState = {
  searchActive: false,
  backdropActive: false,
  marketNavActive: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {

    case types.TOGGLE_SEARCH:
      const searchActive = action.payload === undefined ? !state.searchActive : action.payload
      return { ...state, searchActive, backdropActive: searchActive };

    case types.TOGGLE_BACKDROP:
        const backdropActive = action.payload === undefined ? !state.backdropActive : action.payload
        return { ...state, backdropActive: backdropActive };
  
    case types.TOGGLE_MARKET_NAV:
      const marketNavActive = action.payload === undefined ? !state.marketNavActive : action.payload
      return { ...state, marketNavActive: marketNavActive };

    default:
      return state;
  }
}
