import React from 'react'
import PropTypes from 'prop-types'
import Sidebar from './Sidebar'

function Account(props) {
    return (
        <>
              <div className="filemgr-wrapper">
<Sidebar />

        <div className="filemgr-content">
          {/* <MarketHeader ticker={ticker} instrument={activeInstrument} /> */}

            Header

          <div className="filemgr-content-body" id="main-content">
            <div className="pd-20 pd-lg-25 pd-xl-30">
                    fooo
            </div>
          </div>
        </div>
        {/* filemgr-content */}
      </div>
        </>
    )
}

Account.propTypes = {

}

export default Account

