import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import MonthlyAveragesCard from "./cards/MonthlyAveragesCard/MonthlyAveragesCard";
import MonthlyPerformanceCard from "./cards/MonthlyPerformanceCard/MonthlyPerformanceCard";


function Months({ ticker }) {
  return (
    <>
      <Row>
        <Col lg={6}>
            <MonthlyAveragesCard ticker={ticker} />
          </Col>
          <Col lg={6}>
            <MonthlyPerformanceCard ticker={ticker} />
          </Col>
      </Row>
    </>
  );
}

Months.propTypes = {};

export default Months;
