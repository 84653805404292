import axios from "axios";

// export const signupUser = userData =>
//   axios.post("/api/signup/", userData).then(res => res.data);

export const loginUser = (email, password) =>
  axios
    .post("/api/account/login/", { email: email, password: password })
    .then((res) => res.data);

export const logoutUser = () =>
  axios.get("/api/account/logout/").then((res) => res.data);

export const checkLogin = () =>
  axios.get("/api/account/is-logged-in").then((res) => res.data);

// export const getUser = () =>
//   axios.get("/api/accounts/profile/").then(res => res.data);

// export const logoutUser = () =>
//   axios.post("/api/accounts/logout/").then(res => res.data);

// export const verifyUser = verificationData =>
//   axios
//     .post("/api/accounts/verify-registration/", verificationData)
//     .then(res => res.data);
