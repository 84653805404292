import React from "react";
import PropTypes from "prop-types";
import ReactEcharts from "echarts-for-react";
import moment from "moment";
import _ from "underscore";
import {
  legendDefaults,
  xAxisDefaults,
  yAxisDefaults,
  seriesDefaults,
  chartWatermark,
} from "../../../../../../charts/constants";
import { toDate } from "../../../../../../../utils/converter";

function MonthlyAveragesChart({ averages, height }) {
  const currentMonth = moment().month()
  let selectedSeries = _.range(12).map(x => ({
    [moment().month(x).format("MMM")]: x === currentMonth ? true : false
  }));
  selectedSeries = Object.assign(...selectedSeries)

  const options = {
    legend: {
      ...legendDefaults,
      selected: selectedSeries,
    },
    title: {
      text: "Monthly Averages",
      subtext: "Current Month versus average.",
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        animation: false,
      },
    },

    xAxis: {
      ...xAxisDefaults,
      type: "category",
      data: averages[0].x.map((x) => toDate(x, "D")),
    },
    yAxis: [
      {
        ...yAxisDefaults,
        name: "Percent",
        type: "value",
        splitLine: {
          show: false,
        },
      },
    ],
    graphic: [chartWatermark],
    series: averages.map((data, ix) => ({
      ...seriesDefaults,
      name: moment().month(ix).format("MMM"),
      type: "line",
      showSymbol: false,
      data: data.y,
    })),
  };

  return (
    <ReactEcharts
      option={options}
      style={{ height: height || "400px" }}
      theme={"theme"}
    />
  );
}

MonthlyAveragesChart.propTypes = {
  averages: PropTypes.array.isRequired,
  height: PropTypes.string,
};

export default MonthlyAveragesChart;
