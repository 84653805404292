import React from "react";
import PropTypes from "prop-types";

function CardHeader({ title, subtitle }) {
  return (
    <div className="card-header pd-t-20 d-sm-flex align-items-start justify-content-between bd-b-0 pd-b-0">
      <div>
        <h6 className="mg-b-5">{title}</h6>
        {subtitle && <p className="tx-13 tx-color-03 mg-b-0">{subtitle}</p>}
      </div>
    </div>
  );
}

CardHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string
};

export default CardHeader;
