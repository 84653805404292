import React from "react";
import PropTypes from "prop-types";
import ReactEcharts from "echarts-for-react";
import {
  legendDefaults,
  xAxisDefaults,
  yAxisDefaults,
  seriesDefaults,
  chartWatermark
} from "../../../../../charts/constants";
import { toDate } from "../../../../../../utils/converter";

function ComparisonChart({ compareWeeks }) {
  const options = {
    legend: legendDefaults,
    title: {
      text: "Compare Weeks"
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        animation: false
      }
    },

    xAxis: {
      ...xAxisDefaults,
      type: "category",
      data: compareWeeks.index.map(x => toDate(x, "ddd HH:mm"))
    },
    yAxis: [
      {
        ...yAxisDefaults,
        name: "Percent",
        type: "value",
        splitLine: {
          show: false
        }
      }
    ],
    graphic: [chartWatermark],
    series: compareWeeks.data.map((data, ix) => ({
      ...seriesDefaults,
      name: compareWeeks.keys[ix],
      type: "line",
      showSymbol: false,
      data: data,
      lineStyle: {
        width: ix === compareWeeks.data.length - 1 ? 3 : 2,
        type: ix === compareWeeks.data.length - 1 ? "solid" : "dotted"
      }
    }))
  };

  return (
    <ReactEcharts
      option={options}
      style={{ height: "400px" }}
      theme={"theme"}
    />
  );
}

ComparisonChart.propTypes = {
  compareWeeks: PropTypes.object.isRequired
};

export default ComparisonChart;
