import React from 'react'

const LoadingSpinner = props => (
  <svg
    width={50}
    height={50}
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    className="prefix__lds-dual-ring"
    style={{
      background: '0 0',
    }}
    {...props}
  >
    <circle
      cx={50}
      cy={50}
      fill="none"
      strokeLinecap="round"
      r={20}
      strokeWidth={4}
      stroke="#0258D4"
      strokeDasharray="31.41592653589793 31.41592653589793"
      transform="rotate(108.136 50 50)"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        calcMode="linear"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
        dur="1s"
        begin="0s"
        repeatCount="indefinite"
      />
    </circle>
  </svg>
)

export default LoadingSpinner
