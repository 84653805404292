import React from "react";
import PropTypes from "prop-types";
import errorImage from "../../images/error-image.png";
import { useHistory } from "react-router";

function Error404(props) {
  const history = useHistory();

  return (
    <div className="content content-fixed content-auth-alt">
      <div className="container ht-100p tx-center">
        <div className="ht-100p d-flex flex-column align-items-center justify-content-center">
          <div className="wd-70p wd-sm-250 wd-lg-300 mg-b-15">
            <img src={errorImage} className="img-fluid" alt="" />
          </div>
          <h1 className="tx-color-01 tx-24 tx-sm-32 tx-lg-36 mg-xl-b-5">
            404 Page not found
          </h1>
          <h5 className="tx-16 tx-sm-18 tx-lg-20 tx-normal mg-b-20">
            Oopps. The requested page was not found.
          </h5>
          {/* <p className="tx-color-03 mg-b-30">
            The server encountered an internal server error and was unable to
            complete your request.
          </p> */}
          <div className="mg-b-40">
            <button
              className="btn btn-white pd-x-30"
              onClick={() => history.push("/")}
            >
              Back to Home
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

Error404.propTypes = {};

export default Error404;
