import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactEcharts from "echarts-for-react";
import moment from "moment-timezone";
// import { echartsTimeFormat, shortTimeFormat } from "../../../utils/formatter";
import { getHistoricalData, getCharts } from "../../services/market";
import { chartWatermark, chartWatermarkBottomLeft, legendDefaults, xAxisDefaults, yAxisDefaults, seriesDefaults } from "./constants";
import theme from "./theme";

function YearChart({ ticker }) {
  const [thisYear, setThisYear] = useState(null);
  const [charts, setCharts] = useState(null);

  useEffect(() => {
    const dt = new Date(new Date().getFullYear(), 0, 1);
    const params = {
      ticker: ticker,
      start_datetime: dt.getTime()
    };
    getHistoricalData(params).then(data => setThisYear(data.data));
    getCharts(ticker, ["year_avg"]).then(data => setCharts(data));
  }, [ticker]);

  if (!charts) {
    return null;
  }
  if (!thisYear) {
    return null;
  }

  const yearAvg = charts.find(x => x.chart_type === "year_avg");
  const yearCurrent = {
      x: thisYear.x.map(x =>
        moment.tz(new Date(x / 1000 / 1000), "Europe/Berlin").tz("Europe/London").format("MM-DD")
      ).map(x => x.replace("2000-", "")),
      y: thisYear.y.slice()
  }

  
//   thisYear.x = thisYear.x.map(x =>
//     moment(new Date(x / 1000 / 1000)).format("MM-DD")
//   );
  yearAvg.x = yearAvg.x.map(x => x.replace("2000-", ""));

  const options = {
    legend: legendDefaults,
    title: {
      text: "Average Year",
      subtext: "Current Year and the average Year."
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        animation: false
      }
    },
    
    xAxis: {
      ...xAxisDefaults,
      type: "category",
      // splitLine: {
      //     show: false
      // },
      data: yearAvg.x
    },
    yAxis: [
      {
        ...yAxisDefaults,
        name: "Price",
        type: "value",
        splitLine: {
          show: false
        }
      },
      {
        ...yAxisDefaults,
        type: "value",
        splitLine: {
          show: false
        }
      }
    ],
    graphic: [chartWatermark],
    series: [
      {
        ...seriesDefaults,
        name: yearAvg.name,
        type: "line",
        yAxisIndex: 1,
        showSymbol: false,
        data: yearAvg.y.map((y, ix) => {
          return { value: [yearAvg.x[ix], y] };
        })
      },
      {
        ...seriesDefaults,
        name: ticker,
        type: "line",
        showSymbol: false,
        data: yearCurrent.y.map((y, ix) => {
          return { value: [yearCurrent.x[ix], y] };
        })
      }
    ],
    grid: {
      
      // left: 50,
      // top: 50,
      // right: 50,
      // bottom: 50
    }
  };

  /*   if (additionalCharts){
      additionalCharts.map(
          chart => {
              options.series.push({
                name: chart.name,
                type: "line",
                yAxisIndex: 1,
                // showSymbol: false,
                // hoverAnimation: false,
                data: chart.y.map((y, ix) => {
                  return { value: [xData[ix], y] };
                }) 
              })
          })
  } */

  return (
    <ReactEcharts
      option={options}
      style={{ height: "400px" }}
      theme={"theme"}
    />
  );
}

YearChart.propTypes = {};

export default YearChart;
