import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";

function PerformanceTable({ instruments }) {
  return (
    <div className="table-responsive">
      <table className="table table-dashboard mg-b-0">
        <thead>
          <tr>
            <th>Market</th>
            <th className="text-right">Week</th>
            <th className="text-right">Month</th>
            <th className="text-right">Quarter</th>
            <th className="text-right">Year</th>
          </tr>
        </thead>
        <tbody>
          {instruments.map(x => (
            <tr>
              <td className="tx-color-03">
                <Link to={`/market/${x.ticker.toLowerCase()}`}>{x.name}</Link>
              </td>
              <td
                className={classNames(
                  "text-right tx-medium",
                  x.wtd_pct > 0 ? "tx-ms-blue" : "tx-pink"
                )}
              >
                {x.wtd_pct}%
              </td>
              <td
                className={classNames(
                  "text-right tx-medium",
                  x.mtd_pct > 0 ? "tx-ms-blue" : "tx-pink"
                )}
              >
                {x.mtd_pct}%
              </td>
              <td
                className={classNames(
                  "text-right tx-medium",
                  x.qtd_pct > 0 ? "tx-ms-blue" : "tx-pink"
                )}
              >
                {x.qtd_pct}%
              </td>
              <td
                className={classNames(
                  "text-right tx-medium",
                  x.ytd_pct > 0 ? "tx-ms-blue" : "tx-pink"
                )}
              >
                {x.ytd_pct}%
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

PerformanceTable.propTypes = {
  instruments: PropTypes.array.isRequired
};

export default PerformanceTable;
