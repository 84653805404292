import React from "react";
import PropTypes from "prop-types";
import LoadingSpinner from "../../svg/LoadingSpinner";

function Loading(props) {
  return (
    <div style={{ textAlign: "center" }}>
      <LoadingSpinner />
    </div>
  );
}

Loading.propTypes = {};

export default Loading;
