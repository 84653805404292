import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";
import SentimentCard from "../../../../cards/SentimentCard";
import NewsList from "../../NewsList";
import YearChart from "../../../../charts/YearChart";
import RelatedPerformanceCard from "../../../../cards/RelatedPerformanceCard";
import Card from "../../../../layout/card/Card";
import CardBody from "../../../../layout/card/CardBody";
import SeasonalityCard from "./cards/SeasonalityCard/SeasonalityCard";
import RelatedInstrumentsCard from "./cards/RelatedInstrumentsCard";

function Overview({ ticker, instrument }) {
  return (
    <>
      <Row>
        <Col lg={12}>
          <SeasonalityCard ticker={ticker} />
        </Col>
        {/* <Col lg={4}>
          <Row>
            <Col lg={12}>
              <SentimentCard ticker={ticker} />
            </Col>
            <Col lg={12}>
              <RelatedInstrumentsCard ticker={ticker} />
            </Col>
          </Row>
        </Col>
        <Col lg={12}>
          <Card>
            <div className="card-header pd-t-20 d-sm-flex align-items-start justify-content-between bd-b-0 pd-b-0">
              <div>
                <h6 className="mg-b-5">News</h6>
                <p className="tx-13 tx-color-03 mg-b-0">
                      Your sales and referral earnings over the last 30 days
                    </p>
              </div>
            </div>
            <NewsList ticker={ticker} />
          </Card>
        </Col> */}
      </Row>
      {/* <Row>
        <Col lg={4}>
          <RelatedPerformanceCard instrument={instrument} />
        </Col>
      </Row> */}
    </>
  );
}

Overview.propTypes = {};

export default Overview;
