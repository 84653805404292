import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Col, Row, FormGroup, Label, Input, Button } from "reactstrap";
import { animateScroll } from "react-scroll";
import Datetime from "react-datetime";

import Card from "../../../../layout/card/Card";
import CardBody from "../../../../layout/card/CardBody";
import CardHeader from "../../../../layout/card/CardHeader";
import ResultChart from "./ResultChart";
import DateRange from "../../../../inputs/DateRange";

function CustomSeasonality({ ticker }) {

  const [dates, setDates] = useState({fromDate: null, toDate: null})
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);

  const onDateChange = newDates => {
    setDates({...dates, ...newDates})
  }

  const scrollToBottom = () => {
    animateScroll.scrollToBottom({
      duration: 150,
      containerId: "main-content",
    });
  };

  const onSubmit = () => {
    setResult(null);
    setLoading(true);

    const params = {
      ticker: ticker,
      from_date: dates.fromDate,
      to_date: dates.toDate
    };

    axios.post("/api/custom-study", params).then((res) => {
      setLoading(false);
      setResult(res.data);
    });

    scrollToBottom();
  };

  useEffect(() => {
    if (result) {
      scrollToBottom();
    }
  }, [result]);

  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardHeader title="Custom Seasonality" />

            <CardBody>
              <Row>
                <Col lg={12}>
                  <p>
                    Select a timeframe to run custom seasonality studies.
                  </p>
                 
                </Col>
              </Row>
              <hr />
              <Row>
                <Col lg={12}>
                  <FormGroup>
                    <Label for="exampleText">Select Date Range</Label>
                    <DateRange 
                    dates={dates}
                    onChange={onDateChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Button
                color="primary"
                onClick={() => onSubmit()}
                style={{ float: "right" }}
                disabled={!(dates.fromDate && dates.toDate)}
              >
                Run Calculations
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
        <div id="result-container">
          {(result || loading) && (
            <Row>
              <Col lg={12}>
                <Card>
                  <CardHeader title="Result" />
                  <CardBody loading={loading}>
                    {result && <ResultChart ticker={ticker} data={result} />}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
    </>
  );
}

CustomSeasonality.propTypes = {
  ticker: PropTypes.string,
};

export default CustomSeasonality;
