import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from "reactstrap";
import SentimentCard from './cards/SentimentCard';

function Sentiment({ticker}) {

    return (
        <>
        <Row>
          <Col lg={12}>
            <div style={{ height: "70vh" }}>
                <SentimentCard ticker={ticker} />
            </div>
          </Col>
        </Row>
      </>
    )
}

Sentiment.propTypes = {
    ticker: PropTypes.string.isRequired
}

export default Sentiment

