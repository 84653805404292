import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getCharts } from "../../../../../../../services/market";
import Card from "../../../../../../layout/card/Card";
import CardBody from "../../../../../../layout/card/CardBody";
import YearlyVolatilityChart from "./YearlyVolatilityChart";

function YearlyVolatilityCard({ ticker }) {
  const [loading, setLoading] = useState(true);
  const [charts, setCharts] = useState([]);

  // update chart data when ticker changes
  useEffect(() => {
    setLoading(true);
    getCharts(ticker, ["yearly_volatility"]).then(data => {
      setCharts(data);
      setLoading(false);
    });
  }, [ticker]);

  return (
    <Card>
      <CardBody loading={loading}>
        <YearlyVolatilityChart volatility={charts[0]} />
      </CardBody>
    </Card>
  );
}

YearlyVolatilityCard.propTypes = {
  ticker: PropTypes.string.isRequired
};

export default YearlyVolatilityCard;
