import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import CardBody from "../../../../../../layout/card/CardBody";
import Card from "../../../../../../layout/card/Card";
import { getHistoricalData, getStats } from "../../../../../../../services/market";
import CurrentYearChart from "./CurrentYearChart";

function CurrentYearCard({ ticker }) {
  const [loading, setLoading] = useState(true);
  const [thisYear, setThisYear] = useState(null);
  const [stats, setStats] = useState(null);

  const historicalDataParams = {
    ticker: ticker,
    start_datetime: moment()
      .startOf("year") // todo: attention with timezone?!
      .valueOf(),
    resolution: "D",
    candlestick: true
  };

  useEffect(() => {
    if (thisYear && stats) {
      setLoading(false);
    }
  }, [thisYear, stats]);

  useEffect(() => {
    getHistoricalData(historicalDataParams).then(data =>
      setThisYear(data.data)
    );
    getStats(ticker, ["year"]).then(data => setStats(data));
  }, [ticker]);

  return (
    <Card>
      <CardBody loading={loading}>
      <CurrentYearChart thisYear={thisYear} stats={stats} />
      </CardBody>
    </Card>
  );
}

CurrentYearCard.propTypes = {
  ticker: PropTypes.string.isRequired
};

export default CurrentYearCard;
