import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import MarketSearchMini from "../../ui/MarketSearchMini";
import { toggleMarketNav } from "../../../redux/ducks/layout/actions";

function Sidebar({ ticker, activeInstrument }) {
  const marketNavActive = useSelector((state) => state.layout.marketNavActive);
  const dispatch = useDispatch();

  useEffect(() => {
    if (marketNavActive) {
      document.body.classList.add("filemgr-sidebar-show");
    } else {
      document.body.classList.remove("filemgr-sidebar-show");
    }
    return () => {
      document.body.classList.remove("filemgr-sidebar-show");
    };
  }, [marketNavActive]);

  const onLinkClick = () => dispatch(toggleMarketNav(false));

  return (
    <>
      {marketNavActive && (
        <div
          style={{
            zIndex: "40",
            position: "fixed",
            top: "-10vh",
            left: "0",
            width: "100vw",
            height: "110vh",
            opacity: "0",
          }}
          onClick={() => dispatch(toggleMarketNav(false))}
        ></div>
      )}
      <div className="filemgr-sidebar">
        <div className="filemgr-sidebar-header">
          {/* 
        <div className="dropdown dropdown-icon flex-fill">
          <button
            className="btn btn-xs btn-block btn-white"
            data-toggle="dropdown"
          >
            New <i data-feather="chevron-down" />
          </button>
    
        </div>
        <div className="dropdown dropdown-icon flex-fill mg-l-10">
          <button
            className="btn btn-xs btn-block btn-primary"
            data-toggle="dropdown"
          >
            + Favorites <i data-feather="chevron-down" />
          </button>
                  </div>
       */}
          {/* <div className="search-form"> */}

          {/* <MarketSearchMini /> */}

          {/* </div> */}
        </div>
        {/* filemgr-sidebar-header */}
        <div className="filemgr-sidebar-body">
          <div className="pd-t-20 pd-b-10 pd-x-10">
            <label className="tx-sans tx-uppercase tx-medium tx-10 tx-spacing-1 tx-color-03 pd-l-10">
              Content
            </label>
            <nav className="nav nav-sidebar tx-13">
              <NavLink
                onClick={onLinkClick}
                to={`/market/${ticker}/`}
                className="nav-link"
                exact
              >
                <span>Overview</span>
              </NavLink>
              <NavLink
                onClick={onLinkClick}
                to={`/market/${ticker}/years`}
                className="nav-link"
              >
                <span>Years</span>
              </NavLink>
              <NavLink
                onClick={onLinkClick}
                to={`/market/${ticker}/months`}
                className="nav-link"
              >
                <span>Months</span>
              </NavLink>
              <NavLink
                onClick={onLinkClick}
                to={`/market/${ticker}/weeks`}
                className="nav-link"
              >
                <span>Weeks</span>
              </NavLink>
              <NavLink
                onClick={onLinkClick}
                to={`/market/${ticker}/weekdays`}
                className="nav-link"
              >
                <span>Weekdays</span>
              </NavLink>
              <NavLink
                onClick={onLinkClick}
                to={`/market/${ticker}/time-of-day`}
                className="nav-link"
              >
                <span>Time of Day</span>
              </NavLink>
              {activeInstrument && activeInstrument.has_sentiment_data && (
                <NavLink
                  onClick={onLinkClick}
                  to={`/market/${ticker}/sentiment`}
                  className="nav-link"
                >
                  <span>Sentiment</span>
                </NavLink>
              )}
            </nav>
          </div>
          <div className="pd-10">
            <label className="tx-sans tx-uppercase tx-medium tx-10 tx-spacing-1 tx-color-03 pd-l-10">
              Tools
            </label>
            <nav className="nav nav-sidebar tx-13">
              {/* <NavLink
                onClick={onLinkClick}
                to={`/market/${ticker}/custom-seasonality`}
                className="nav-link"
              >
                <span>Custom Seasonality</span>
              </NavLink> */}
              <NavLink
                onClick={onLinkClick}
                to={`/market/${ticker}/custom-studies`}
                className="nav-link"
              >
                <span>Custom Studies</span>
              </NavLink>
              <NavLink
                onClick={onLinkClick}
                to={`/market/${ticker}/patterns`}
                className="nav-link"
              >
                <span>Pattern Matches</span>
              </NavLink>
              <NavLink
                onClick={onLinkClick}
                to={`/market/${ticker}/chart`}
                className="nav-link"
              >
                <span>Interactive Chart</span>
              </NavLink>
              <NavLink
                onClick={onLinkClick}
                to={`/market/${ticker}/compare`}
                className="nav-link"
              >
                <span>Compare</span>
              </NavLink>
              {/* <a href="#"className="nav-link"><i data-feather="video" /> <span>Videos</span></a>
                <a href="#"className="nav-link"><i data-feather="music" /> <span>Audio</span></a>
                <a href="#"className="nav-link"><i data-feather="package" /> <span>Zip Files</span></a> */}
            </nav>
          </div>
          {/* 
        <div className="pd-y-10 pd-x-20">
          <label className="tx-sans tx-uppercase tx-medium tx-10 tx-spacing-1 tx-color-03 mg-b-15">
            Storage Status
          </label>
          <div className="media">
            <i data-feather="database" className="wd-30 ht-30" />
            <div className="media-body mg-l-10">
              <div className="tx-12 mg-b-4">10.2GB used of 50GB</div>
              <div className="progress ht-3 mg-b-20">
                <div
                  className="progress-bar wd-15p"
                  role="progressbar"
                  aria-valuenow={15}
                  aria-valuemin={0}
                  aria-valuemax={100}
                />
              </div>
            </div>
          </div>
          <p className="tx-12">
            Get 2TB (2,000GB) of storage now and get 40% off. Offers ends soon.{" "}
            <a href="#">Learn more</a>
          </p>
        </div>
       */}
        </div>
        {/* filemgr-sidebar-body */}
      </div>
    </>
  );
}

Sidebar.propTypes = {
  ticker: PropTypes.string.isRequired,
  activeInstrument: PropTypes.object.isRequired,
};

export default Sidebar;
